import React, { useEffect, useState } from 'react';

import NavBar from 'components/NavBar';
import ProgressButton from 'components/ProgressButton';
import CheckBox from 'components/CheckBox';
import Alert from 'components/Alert';
import useJournalStore from 'store/journalStore';
import { getQuestionRequired } from 'helper/helper';

import useStyles from './styles';

const CheckBoxQuestion = ({ title, uid, options, isRequired }) => {
  const classes = useStyles();
  const { updateAnswerByUID, error, surveyAnswers } = useJournalStore();
  const [checkBoxValues, setCheckBoxValues] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleCheckChange = (checked, clickedAnswer) => {
    if (!clickedAnswer) return;
    const updatedCheckboxValues = [...checkBoxValues];
    if (checked) {
      updatedCheckboxValues.push(clickedAnswer);
      setCheckBoxValues(updatedCheckboxValues);
    } else {
      const indexOfSelectedValue = updatedCheckboxValues.findIndex((checkbox) => checkbox.key === clickedAnswer.key);
      if (indexOfSelectedValue > -1) {
        const updatedCheckBox = [
          ...updatedCheckboxValues.slice(0, indexOfSelectedValue),
          ...updatedCheckboxValues.slice(indexOfSelectedValue + 1),
        ];
        setCheckBoxValues(updatedCheckBox);
      }
    }
  };

  const handleAnswer = async () => {
    if (!isRequired && checkBoxValues.length === 0) return true;
    setLoading(true);
    const answerFormData = new FormData();
    answerFormData.append('Uid', uid);
    answerFormData.append('AnswerText', JSON.stringify(checkBoxValues));
    const resp = await updateAnswerByUID(answerFormData);
    setLoading(false);
    setCheckBoxValues([]);
    return resp;
  };

  const isCheckedOption = (key) => {
    if (checkBoxValues && checkBoxValues.length) {
      const index = checkBoxValues.findIndex((value) => value.key === key);
      return index > -1;
    }
    return false;
  };

  useEffect(() => {
    setCheckBoxValues([]);
    const availableAnswer = surveyAnswers?.find((answer) => answer.uid === uid);
    if (!availableAnswer?.answer) return;
    const { answer } = availableAnswer;
    if (answer) {
      setCheckBoxValues(JSON.parse(answer));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uid]);

  return (
    <>
      <NavBar title={title} />
      <div className={classes.checkboxQuestionWrapper}>
        <div className={classes.checkBoxQuestion}>
          {!!options.length &&
            options.map((option) => (
              <CheckBox
                option={option}
                checked={isCheckedOption(option.key)}
                click={handleCheckChange}
                key={option.key}
              />
            ))}
        </div>
      </div>
      {error && <Alert severity="error" message={error} />}
      <ProgressButton
        nextButtonDisabled={getQuestionRequired(isRequired, !checkBoxValues.length)}
        nextButtonClicked={handleAnswer}
        loading={loading}
      />
    </>
  );
};

export default CheckBoxQuestion;
