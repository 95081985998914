import React from 'react';
import { IconButton, Typography } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import useJournalStore from 'store/journalStore';
import cs from 'classnames';

import leftArrow from 'assets/icons/arrowLeft.svg';
import useStyles from './styles';

const NavBar = ({ title = '', description, isFeed = false, handleSurveyClick, isTextBlock = false }) => {
  const classes = useStyles();
  const history = useHistory();
  const { surveyQuestions = [] } = useJournalStore();
  const { questionId, surveyId } = useParams();

  const handleBackClick = () => {
    if (isFeed) {
      return handleSurveyClick();
    }
    if (surveyQuestions.length && !!questionId) {
      const currentIndex = surveyQuestions.findIndex((question) => question.uid === questionId);
      if (currentIndex === -1) {
        return history.goBack();
      } else if (currentIndex === 0) {
        return history.push('/survey');
      } else {
        const previousQuestion = surveyQuestions[currentIndex - 1];
        const { uid } = previousQuestion;
        return history.push(`/survey/${surveyId}/${uid}`);
      }
    }
    history.goBack();
  };

  return (
    <div className={cs(classes.wrapper, { [classes.textBlock]: isTextBlock })}>
      <IconButton className={classes.leftArrowIcon} onClick={handleBackClick}>
        <img src={leftArrow} alt="Left Arrow" />
      </IconButton>
      <div className={classes.titleWrapper}>
        <Typography variant="subtitle1">{title}</Typography>
        {description && <div className={classes.description}>{description}</div>}
      </div>
    </div>
  );
};

export default NavBar;
