import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import ProgressBar from 'components/ProgressBar';
import TextAreaQuestion from './Questions/TextAreaQuestion';
import AudioQuestion from './Questions/AudioQuestion';
import VideoQuestion from './Questions/VideoQuestion';
import PhotoQuestion from './Questions/PhotoQuestion';
import MoodQuestion from './Questions/MoodQuestion';
import CheckBoxQuestion from './Questions/CheckBoxQuestion';
import DropdownQuestion from './Questions/DropdownQuestion';
import RadioQuestion from './Questions/RadioQuestion';
import RatingQuestion from './Questions/RatingQuestion';
import TextQuestion from './Questions/TextQuestion';
import DateQuestion from './Questions/DateQuestion';
import FileUpload from './Questions/FileUpload';
import TextBlockQuestion from './Questions/TextBlockQuestion';
import CheckBoxLabelQuestion from './Questions/CheckBoxLabelQuestion';
import { questionType } from 'constants/questionType';
import useJournalStore from 'store/journalStore';

import useStyles from './styles';

const Survey = () => {
  const classes = useStyles();
  const history = useHistory();
  const {
    surveyQuestions,
    doGetFilteredSurvey,
    surveys,
    doGetSurveyQuestions,
    doGetSurveyAnswers,
    loadingAnswers,
    loadingQuestions,
    doResetError,
  } = useJournalStore();
  const { questionId, surveyId } = useParams();
  const surveyLength = surveys.length;
  const [currentQuestion, setCurrentQuestion] = useState(null);

  useEffect(() => {
    if (!surveyLength) {
      doGetFilteredSurvey(2);
    }
    if (!surveyQuestions.length) {
      doGetSurveyQuestions(surveyId);
      doGetSurveyAnswers(surveyId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [surveyLength, surveyQuestions.length]);

  useEffect(() => {
    if (surveyQuestions.length) {
      const filteredQuestion = surveyQuestions.find((question) => question.uid === questionId);
      if (filteredQuestion) {
        setCurrentQuestion(filteredQuestion);
        return;
      }
      const currentSurvey = surveys.find((survey) => survey.surveyResponseId === +surveyId);
      if (currentSurvey) {
        const { lastAttendedFormQuestionAnswerUid } = currentSurvey;
        if (lastAttendedFormQuestionAnswerUid) {
          // get the index of previously attempted question
          const indexOfPreviousQuestion = surveyQuestions.findIndex(
            (question) => question.uid === lastAttendedFormQuestionAnswerUid,
          );
          if (indexOfPreviousQuestion !== -1 && indexOfPreviousQuestion !== surveyQuestions.length - 1) {
            // send user to the next question
            const { uid } = surveyQuestions[indexOfPreviousQuestion + 1];
            return history.push(`/survey/${surveyId}/${uid}`);
          }
        }
        // fallback to first question
        const { uid } = surveyQuestions[0];
        history.push(`/survey/${surveyId}/${uid}`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionId, surveyQuestions.length, surveyLength]);

  useEffect(() => {
    doResetError();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionId]);

  const getCurrentQuestion = () => {
    const { questionText, questionTypeId, uid, options, description, link, isRequired } = currentQuestion;
    switch (questionTypeId) {
      case questionType.text:
        return <TextQuestion title={questionText} uid={uid} isRequired={isRequired} />;
      case questionType.email:
        return (
          <TextQuestion
            title={questionText}
            uid={uid}
            type="email"
            placeholder="sample@mail.com"
            isRequired={isRequired}
          />
        );
      case questionType.number:
        return <TextQuestion title={questionText} uid={uid} type="number" isRequired={isRequired} />;
      case questionType.date:
        return <DateQuestion title={questionText} uid={uid} isRequired={isRequired} />;
      case questionType.radioList:
      case questionType.radioMatrix:
        return <RadioQuestion title={questionText} uid={uid} options={options} isRequired={isRequired} />;
      case questionType.audio:
        return <AudioQuestion title={questionText} uid={uid} description={description} isRequired={isRequired} />;
      case questionType.video:
        return <VideoQuestion title={questionText} uid={uid} description={description} isRequired={isRequired} />;
      case questionType.fileUpload:
        return <FileUpload title={questionText} uid={uid} description={description} isRequired={isRequired} />;
      case questionType.photo:
        return (
          <PhotoQuestion
            title={questionText}
            uid={uid}
            description={description}
            isRequired={isRequired}
            isRequired={isRequired}
          />
        );
      case questionType.mood:
        return <MoodQuestion title={questionText} uid={uid} options={options} isRequired={isRequired} />;
      case questionType.checkBoxList:
        return <CheckBoxQuestion title={questionText} uid={uid} options={options} isRequired={isRequired} />;
      case questionType.checkBox:
        return <CheckBoxLabelQuestion title={questionText} uid={uid} link={link} isRequired={isRequired} />;
      case questionType.dropdown:
        return <DropdownQuestion title={questionText} uid={uid} options={options} isRequired={isRequired} />;
      case questionType.rating:
        return <RatingQuestion title={questionText} uid={uid} options={options} isRequired={isRequired} />;
      case questionType.textArea:
        return <TextAreaQuestion title={questionText} uid={uid} isRequired={isRequired} />;
      case questionType.textBlock:
        return <TextBlockQuestion description={description} uid={uid} isRequired={isRequired} />;
      default:
        return <TextQuestion title={questionText} uid={uid} isRequired={isRequired} />;
    }
  };

  const handleFormSubmit = (event) => event.preventDefault();

  const renderSurvey = currentQuestion && !loadingAnswers && !loadingQuestions;

  return (
    <div className={classes.wrapper}>
      {renderSurvey && (
        <>
          <ProgressBar />
          <form onSubmit={handleFormSubmit}>
            <div className={classes.questionWrapper}>{getCurrentQuestion()}</div>
          </form>
        </>
      )}
    </div>
  );
};

export default Survey;
