import { clearStorage } from '../http/request';

export const getQuestionRequired = (isRequired, valid) => {
  if (!isRequired) {
    return false;
  } else {
    return valid;
  }
};

export const getEmailQuestionRequired = (isRequired, valid) => {
  if (!isRequired && !valid) {
    return false;
  } else {
    return valid;
  }
};

export const getFileUploadedRequired = (isRequired, uploadedFile) => {
  if (isRequired && !uploadedFile) {
    return false;
  }
  if (!isRequired && !uploadedFile) {
    return true;
  }
  return uploadedFile;
};

export const logoutUser = async () => {
  await clearStorage();
  window.location.reload();
};
