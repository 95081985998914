import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  textBlockWrapper: {
    height: '100%',
    maxHeight: 250,
    overflow: 'auto',
    whiteSpace: 'break-spaces',
  },
}));

export default useStyles;
