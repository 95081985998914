import React, { useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import SignUpForm from './SignUpForm';
import ImageUpload from './ImageUpload';
import SignUpComplete from './SignUpComplete';
import LandingComponent from './LandingComponent';
import Login from './Login';

import useStyles from './styles';

const Onboarding = () => {
  const classes = useStyles();
  const { inviteCode } = useParams();
  const { pathname } = useLocation();
  const [currentStep, setCurrentStep] = useState(1);
  const [currentTitle, setCurrentTitle] = useState('');

  const updateStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const getCurrentPage = () => {
    switch (currentStep) {
      case 2:
        return <SignUpForm updateStep={updateStep} />;
      case 3:
        return <ImageUpload updateStep={updateStep} />;
      case 4:
        return <SignUpComplete />;
      case 5:
        return <Login />;
      case 1:
      default:
        return <LandingComponent updateStep={updateStep} />;
    }
  };

  useEffect(() => {
    switch (currentStep) {
      case 2:
        setCurrentTitle('Let’s set up your account.');
        break;
      case 3:
        setCurrentTitle('Take or upload a profile picture.');
        break;
      case 4:
      default:
        setCurrentTitle('');
    }
  }, [currentStep]);

  useEffect(() => {
    if (pathname === '/onboarding/login') {
      setCurrentStep(5);
    } else {
      setCurrentStep(1);
    }
  }, [pathname]);

  useEffect(() => {
    if (inviteCode) {
      setCurrentStep(2);
    }
  }, [inviteCode]);

  return (
    <div className={classes.wrapper}>
      <div className={classes.gradientWrapper}>
        <div className={classes.signUpFormWrapper}>
          {currentStep > 1 && currentStep < 5 && (
            <div className={classes.navBar}>
              <div className={classes.title}>{currentTitle}</div>
            </div>
          )}
          {getCurrentPage()}
        </div>
      </div>
    </div>
  );
};

export default Onboarding;
