import React, { useEffect, useState, useRef } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';

import NavBar from 'components/NavBar';
import ProgressButton from 'components/ProgressButton';
import useJournalStore from 'store/journalStore';
import Alert from 'components/Alert';
import { getQuestionRequired } from 'helper/helper';

import useStyles from './styles';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const TextAreaQuestion = ({ title, uid, isRequired }) => {
  const classes = useStyles();
  const editorRef = useRef();
  const { updateAnswerByUID, error, surveyAnswers } = useJournalStore();
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [editorText, setEditorText] = useState(null);
  const [loading, setLoading] = useState(false);
  const toolBar = {
    options: ['textAlign', 'inline'],
    inline: {
      options: ['bold', 'italic', 'underline'],
      bold: { className: classes.toolBarButtonClassName },
      italic: { className: classes.toolBarButtonClassName },
      underline: { className: classes.toolBarButtonClassName },
    },
    textAlign: {
      className: classes.toolBarButtonClassName,
      options: ['left', 'center', 'right'],
      left: { className: classes.toolBarButtonClassName },
      center: { className: classes.toolBarButtonClassName },
      right: { className: classes.toolBarButtonClassName },
    },
  };
  const handleEditorChange = (editorValue) => {
    setEditorText(editorState.getCurrentContent().getPlainText('\u0001'));
    setEditorState(editorValue);
  };

  const handleAnswer = async () => {
    if (!isRequired && !editorText) return true;
    setLoading(true);
    const answerText = JSON.stringify(convertToRaw(editorState.getCurrentContent()));
    const answerFormData = new FormData();
    answerFormData.append('Uid', uid);
    answerFormData.append('AnswerText', answerText);
    const resp = await updateAnswerByUID(answerFormData);
    setEditorState(EditorState.createEmpty());
    setEditorText(null);
    setLoading(false);
    return resp;
  };

  useEffect(() => {
    editorRef.current.focusEditor();
    const availableAnswer = surveyAnswers?.find((answer) => answer.uid === uid);
    if (!availableAnswer?.answer) return;
    const { answer } = availableAnswer;
    if (answer) {
      const content = convertFromRaw(JSON.parse(answer));
      const editorValue = EditorState.createWithContent(content);
      setEditorState(editorValue);
    } else {
      setEditorState(EditorState.createEmpty());
      setEditorText('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uid]);

  return (
    <div className={classes.wrapper}>
      <NavBar title={title} />
      <Editor
        ref={editorRef}
        editorState={editorState}
        toolbarClassName={classes.toolBarClassName}
        wrapperClassName={classes.editorWrapperClassName}
        editorClassName={classes.editorClassName}
        toolbar={toolBar}
        onEditorStateChange={handleEditorChange}
        placeholder="Start Writing..."
      />
      {error && <Alert severity="error" message={error} />}
      <ProgressButton
        nextButtonDisabled={getQuestionRequired(isRequired, !editorText)}
        nextButtonClicked={handleAnswer}
        loading={loading}
      />
    </div>
  );
};

export default TextAreaQuestion;
