export const questionType = {
  dropdown: 6,
  video: 13,
  audio: 14,
  rating: 17,
  mood: 16,
  date: 8,
  text: 1,
  textArea: 2,
  textBlock: 11,
  checkBoxList: 4,
  checkBox: 5,
  fileUpload: 12,
  photo: 15,
  radioList: 3,
  radioMatrix: 10,
  email: 7,
  number: 9,
};
