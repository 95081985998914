import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    marginTop: 50,
    alignItems: 'center',
  },
  contentWrapper: {
    width: 600,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      width: 302,
    },
  },
  introImg: {
    width: 470,
    height: 440,
    margin: '46px 0 60px 0',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      height: 'auto',
    },
  },
  getStartedButton: {
    background: theme.palette.success.main,
    color: '#FFFFFF',
    width: 300,
    height: 50,
    fontFamily: 'Montserrat',
    fontSize: 18,
    borderRadius: 10,
  },
  surveyName: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
  backIconWrapper: {
    display: 'none',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      display: 'block',
      marginBottom: 15,
    },
  },
  desktopTitle: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
}));

export default useStyles;
