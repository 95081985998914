import create from 'zustand';

import axios, { getValueFromStorage } from 'http/request';
import { clearStorage } from 'http/request';

const userProfileStore = create((set, get) => ({
  userData: {},
  error: '',
  loading: false,
  doGetUserById: async () => {
    try {
      set({
        loading: true,
      });
      const userId = await getValueFromStorage('userId');
      if (!userId) {
        await clearStorage();
      }
      const resp = await axios.get(`/user/get-user?Id=${userId}`);
      set({ userData: resp?.data, loading: false });
    } catch ({ response: { data } }) {
      set({
        error: data?.title,
        loading: false,
      });
    }
  },
  setUserProfilePhoto: (image) => {
    const { userData } = get();
    set({
      userData: {
        ...userData,
        profileUrl: image,
      },
    });
  },
  doUpdateUserProfile: async (profileData) => {
    try {
      set({
        error: '',
      });
      const userId = await getValueFromStorage('userId');
      profileData.id = userId;
      const resp = await axios.put(`/user/update-profile`, profileData);
      if (resp.status === 200) {
        return true;
      }
    } catch ({ response }) {
      set({
        error: response?.data?.title ? response?.data?.title : response?.data[0].text,
      });
      return false;
    }
  },
  updateUserStore: (updatedProfile) => {
    const { userData } = get();
    set({
      userData: {
        ...userData,
        ...updatedProfile,
      },
    });
  },
}));

export default userProfileStore;
