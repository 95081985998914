import React from 'react';

import useStyles from './styles';

const PhotoAnswer = ({ photo }) => {
  const classes = useStyles();
  return <img src={photo} className={classes.imageWrapper} alt="Answer" />;
};

export default PhotoAnswer;
