import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  photoWrapper: {
    marginTop: 30,
    marginLeft: 88,
    paddingBottom: 90,
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      width: '100%',
    },
    [theme.breakpoints.down('xs')]: {
      alignItems: 'center',
    },
  },
  fileName: {
    display: 'flex',
    fontSize: 18,
    marginTop: theme.spacing(2),
  },
  renameFile: {
    textDecoration: 'underline',
    color: theme.palette.primary.main,
    cursor: 'pointer',
    marginLeft: theme.spacing(5),
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(2),
      marginLeft: 0,
    },
  },
  uploadButton: {
    marginTop: 24,
    boxShadow: '0px 4px 13px #bababa',
    borderRadius: 10,
    fontFamily: 'Montserrat',
    fontWeight: 300,
    fontSize: 18,
    color: theme.palette.primary.main,
    padding: theme.spacing(1, 4),
    minWidth: 265,
    width: 'fit-content',
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
    },
  },
  inputFile: {
    display: 'none',
  },
  modal: {
    borderRadius: 15,
  },
  modalContent: {
    width: 257,
    padding: '28px 29px 40px 29px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontFamily: 'Montserrat',
    fontSize: 22,
    color: theme.palette.text.primary,
  },
  selectImageButton: {
    background: theme.palette.primary.dark,
    borderRadius: 10,
    fontFamily: 'Montserrat',
    fontSize: 18,
    color: '#FFFFFF',
    marginTop: 30,
  },
  descriptionWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '80%',
    marginTop: 20,
    alignItems: 'center',

    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  descriptionText: {
    border: `1px solid ${theme.palette.primary.dark}`,
    borderRadius: 10,
    padding: '20px 27px',
    display: 'flex',
    flexWrap: 'wrap',
    width: '91%',

    [theme.breakpoints.down('xs')]: {
      width: 'calc(100% - 54px)',
    },
  },
  editDescriptionButton: {
    fontFamily: 'Montserrat',
    fontWeight: 300,
    fontSize: 14,
    width: 280,
    color: theme.palette.text.primary,
    marginTop: 10,
  },
  textField: {
    maxWidth: 540,
    width: '100%',
    fontSize: 16,
    '& .MuiOutlinedInput-root': {
      border: `0.864078px solid #00AE85`,
      borderRadius: 10,
      height: 64,
      overflow: 'hidden',
    },
    '& .MuiOutlinedInput-input': {
      padding: '8px 32px',
      fontFamily: 'Montserrat',
      color: theme.palette.text.primary,
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent',
    },

    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent',
    },
    '& .MuiInputBase-input': {
      height: 'inherit',
    },
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
  helperText: {
    fontFamily: 'Montserrat',
    fontSize: 14,
    color: theme.palette.error.dark,
  },
}));

export default useStyles;
