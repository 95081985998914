import { createTheme } from '@material-ui/core';

const theme = createTheme({
  palette: {
    primary: {
      light: '#C3EAE0',
      main: '#00AE85',
      dark: '#00C5A3',
    },
    secondary: {
      main: '#00517C',
    },
    error: {
      light: '#FFC6C6',
      main: '#FF9797',
      dark: '#FF7070',
    },
    warning: {
      light: '#FEEAD2',
      main: '#FFCB8B',
      dark: '#FFAD49',
    },
    info: {
      light: '#B5E5FF',
      main: '#60C8FF',
      dark: '#06A6FB',
    },
    success: {
      light: '#82E3D2',
      main: '#00C5A3', // NOTE: Main is same as dark with an opacity of 15%
      dark: '#00C5A3',
    },
    text: {
      primary: '#282828',
      secondary: '#494949',
      hint: '#B2B2B4',
      disabled: '#F2F2F5',
    },
  },
  typography: {
    h1: {
      fontFamily: 'Montserrat',
      fontWeight: 700,
      fontSize: 44,
      lineHeight: 1.3,
    },
    h2: {
      fontFamily: 'Montserrat',
      fontWeight: 700,
      fontSize: 36,
      lineHeight: 1.3,
    },
    h3: {
      fontFamily: 'Montserrat',
      fontWeight: 700,
      fontSize: 28,
      lineHeight: 1.3,
    },
    h4: {
      fontFamily: 'Montserrat',
      fontWeight: 700,
      fontSize: 24,
      lineHeight: 1.3,
    },
    h5: {
      fontFamily: 'Montserrat',
      fontWeight: 700,
      fontSize: 20,
      lineHeight: 1.3,
    },
    h6: {
      fontFamily: 'Montserrat',
      fontWeight: 700,
      fontSize: 16,
      lineHeight: 1.3,
    },
    subtitle1: {
      fontFamily: 'Montserrat',
      fontSize: 22,
      lineHeight: 1.5,
    },
    body1: {
      fontFamily: 'Montserrat',
    },
    body2: {
      fontFamily: 'Montserrat',
    },
    button: {
      textTransform: 'none',
    },
  },
});

export default theme;
