import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  imageWrapper: {
    width: '100%',
    maxWidth: 380,
    maxHeight: 240,
    objectFit: 'cover',
  },
}));

export default useStyles;
