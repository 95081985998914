import React, { useState, useEffect } from 'react';
import { TextField } from '@material-ui/core';

import NavBar from 'components/NavBar';
import ProgressButton from 'components/ProgressButton';
import Alert from 'components/Alert';
import useJournalStore from 'store/journalStore';
import { getQuestionRequired, getEmailQuestionRequired } from 'helper/helper';

import useStyles from './styles';

const TextQuesiton = ({ title, uid, type = 'text', placeholder = 'Enter Answer', isRequired }) => {
  const classes = useStyles();
  const { updateAnswerByUID, error, surveyAnswers } = useJournalStore();
  const [loading, setLoading] = useState(false);
  const [validationError, setValidationError] = useState('');
  const [answerValue, setAnswerValue] = useState('');

  const ValidatorFunction = (type) => {
    switch (type) {
      case 'email':
        return getEmailQuestionRequired(isRequired, !answerValue || !!validationError);
      case 'text':
      case 'number':
      default:
        return getQuestionRequired(isRequired, !answerValue || !!validationError);
    }
  };

  const handleAnswer = async () => {
    if (!isRequired && !answerValue) return true;
    setLoading(true);
    const answerFormData = new FormData();
    answerFormData.append('Uid', uid);
    answerFormData.append('AnswerText', answerValue);
    const resp = await updateAnswerByUID(answerFormData);
    setAnswerValue('');
    setLoading(false);
    return resp;
  };

  const handleChange = ({ target: { value } }) => {
    setAnswerValue(value);
    if (type === 'email') {
      const emailRegex = /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}/;
      if (!emailRegex.test(value) && value !== '') {
        setValidationError('Enter your email address using the following format: sample@mail.com');
      } else {
        setValidationError('');
      }
    }
  };
  useEffect(() => {
    setAnswerValue('');
    setValidationError('');
    const availableAnswer = surveyAnswers?.find((answer) => answer.uid === uid);
    if (!availableAnswer?.answer) return;
    const { answer } = availableAnswer;
    if (answer) {
      setAnswerValue(answer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uid]);

  return (
    <>
      <NavBar title={title} />
      <div className={classes.textFieldWrapper}>
        <TextField
          type={type}
          inputRef={(input) => input && input.focus()}
          value={answerValue}
          variant="outlined"
          classes={{
            root: classes.textField,
          }}
          placeholder={placeholder}
          onChange={handleChange}
          helperText={validationError}
          FormHelperTextProps={{
            className: classes.helperText,
          }}
        />
      </div>
      {error && <Alert severity="error" message={error} />}
      <ProgressButton nextButtonDisabled={ValidatorFunction(type)} nextButtonClicked={handleAnswer} loading={loading} />
    </>
  );
};

export default TextQuesiton;
