import React from 'react';
import AlertAtom from '@material-ui/lab/Alert';

import useStyles from './styles';

const Alert = ({ severity, message }) => {
  const classes = useStyles();
  return (
    <AlertAtom severity={severity} classes={{ root: classes.root }}>
      {message}
    </AlertAtom>
  );
};

export default Alert;
