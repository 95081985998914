import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  videoWrapper: {
    position: 'relative',
    maxWidth: 380,
    maxHeight: 240,
  },
  videoPlayer: {
    maxWidth: 380,
    maxHeight: 240,
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  playButton: {
    position: 'absolute',
    top: 0,
    width: '100%',
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  iconWrapper: {
    position: 'absolute',
    top: 0,
    display: 'flex',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    zIndex: 999,
    justifyContent: 'center',
    '&:hover > button': {
      display: 'flex',
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
  },
  pauseButton: {
    display: 'none',
  },
}));

export default useStyles;
