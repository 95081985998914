import React, { useEffect, useState } from 'react';
import { RadioGroup } from '@material-ui/core';

import RadioButton from 'components/RadioButton';
import NavBar from 'components/NavBar';
import ProgressButton from 'components/ProgressButton';
import Alert from 'components/Alert';
import useJournalStore from 'store/journalStore';
import { getQuestionRequired } from 'helper/helper';

import useStyles from './styles';

const RadioQuestion = ({ title, uid, options, isRequired }) => {
  const classes = useStyles();
  const { updateAnswerByUID, error, surveyAnswers } = useJournalStore();
  const [radioValue, setRadioValue] = useState('');
  const [loading, setLoading] = useState(false);

  const handleAnswer = async () => {
    if (!isRequired && !radioValue) return true;
    setLoading(true);
    const answerFormData = new FormData();
    answerFormData.append('Uid', uid);
    const selectedOption = options.filter((option) => option.value === radioValue);
    answerFormData.append('AnswerText', JSON.stringify(selectedOption));
    const resp = await updateAnswerByUID(answerFormData);
    setRadioValue('');
    setLoading(false);
    return resp;
  };

  const handleRadioChange = ({ target: { value } }) => setRadioValue(value);

  useEffect(() => {
    setRadioValue('');
    const availableAnswer = surveyAnswers?.find((answer) => answer.uid === uid);
    if (!availableAnswer?.answer) return;
    const { answer } = availableAnswer;
    if (answer) {
      const ans = JSON.parse(answer);
      setRadioValue(ans[0]?.value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uid, surveyAnswers]);

  return (
    <>
      <NavBar title={title} />
      <div className={classes.radioGroupWrapper}>
        <div className={classes.radio}>
          <RadioGroup
            name="radio-question"
            defaultValue={radioValue}
            onChange={handleRadioChange}
            classes={{
              root: classes.radioGroupRoot,
            }}
          >
            {options.length &&
              options.map((option) => (
                <RadioButton key={option.key} label={option.value} value={option.value} selectedValue={radioValue} />
              ))}
          </RadioGroup>
        </div>
      </div>
      {error && <Alert severity="error" message={error} />}
      <ProgressButton
        nextButtonDisabled={getQuestionRequired(isRequired, !radioValue)}
        nextButtonClicked={handleAnswer}
        loading={loading}
      />
    </>
  );
};

export default RadioQuestion;
