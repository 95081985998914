import React, { useState, useEffect, useRef } from 'react';
import { Button, IconButton } from '@material-ui/core';
import { useReactMediaRecorder } from 'react-media-recorder';
import { VoiceRecorder } from 'capacitor-voice-recorder';

import NavBar from 'components/NavBar';
import ProgressButton from 'components/ProgressButton';
import audioWave from 'assets/icons/audio.svg';
import Timer from 'components/Timer';
import playButton from 'assets/icons/playButton.svg';
import DescriptionModal from 'components/DescriptionModal';
import useJournalStore from 'store/journalStore';
import Alert from 'components/Alert';
import env from 'env';
import pauseButton from 'assets/icons/pauseGreen.svg';
import { generateSurveyAnswer } from 'helper/formData';
import { getQuestionRequired } from 'helper/helper';

import useStyles from './styles';

const AudioQuestion = ({ title, uid, description, isRequired }) => {
  const classes = useStyles();
  const audioRef = useRef();
  const { updateAnswerByUID, error, surveyAnswers } = useJournalStore();
  const [audioAnswer, setAudioAnswer] = useState('');
  const [isTimerRunning, setTimerRunning] = useState(false);
  const [isDescriptionModal, setDescriptionModal] = useState(false);
  const [descriptionText, setDescriptionText] = useState(description);
  const [isPlaying, setPlaying] = useState(false);
  const [audioUrl, setAudioUrl] = useState();
  const {
    startRecording,
    stopRecording,
    mediaBlobUrl,
    error: recorderError,
    status,
    clearBlobUrl,
  } = useReactMediaRecorder({
    audio: {
      autoGainControl: true,
      echoCancellation: true,
    },
    blobPropertyBag: { type: 'audio/wav' },
  });
  const [recordingError, setRecordingError] = useState('');
  const [loading, setLoading] = useState(false);

  const handleDiscard = () => {
    setAudioAnswer('');
    setDescriptionText('');
    clearBlobUrl();
    audioRef.current.src = '';
  };

  const startRecordingMobile = async () => {
    try {
      await VoiceRecorder.requestAudioRecordingPermission();
      await VoiceRecorder.startRecording();
      setTimerRunning(true);
    } catch (err) {
      // ToDo: Need to refactor error based on testing on real devices.
      setRecordingError('Something went wrong while save audio!');
    }
  };

  const stopRecordingMobile = async () => {
    try {
      const result = await VoiceRecorder.stopRecording();
      const val = `data:audio/aac;base64,${result.value.recordDataBase64}`;
      setAudioAnswer(val);
      setDescriptionModal(true);
    } catch (err) {
      // ToDo: Need to refactor error based on testing on real devices.
      setRecordingError('Something went wrong while save audio!');
    }
  };

  const startRecordingAudio = () => {
    if (env.isMobile) {
      return startRecordingMobile();
    }
    startRecording();
  };

  const stopRecordingAudio = async () => {
    if (env.isMobile) {
      await stopRecordingMobile();
    } else {
      stopRecording();
    }
    setTimerRunning(false);
  };

  const playRecorderAudio = () => {
    setPlaying(true);
    audioRef.current.play();
  };

  const pauseRecorderAudio = () => {
    setPlaying(false);
    audioRef.current.pause();
  };

  const handleDescriptionModal = () => {
    setDescriptionModal(!isDescriptionModal);
  };

  const handleDescriptionSave = (value) => {
    setDescriptionText(value);
  };

  const handleStopPlaying = () => {
    setPlaying(false);
  };

  const handleAnswer = async () => {
    if (!isRequired && !audioAnswer) return true;
    setLoading(true);
    const answerFormData = await generateSurveyAnswer(uid, audioAnswer, descriptionText);
    const resp = await updateAnswerByUID(answerFormData);
    setLoading(false);
    return resp;
  };

  const convertBlobToFile = async (media) => {
    let audioFile = await fetch(media)
      .then((r) => r.blob())
      .then((blobFile) => new File([blobFile], `formQuestionAnswer.${uid}_audio.mp3`, { type: 'audio/mp3' }));
    setAudioAnswer(audioFile);
    setAudioUrl(media);
  };

  const convertFileToBlob = (answer) => {
    const fileReader = new FileReader();
    fileReader.onload = async (upload) => {
      const result = await upload.target.result;
      setAudioUrl(result);
    };
    fileReader.readAsDataURL(answer);
  };

  useEffect(() => {
    setAudioUrl('');
    const availableAnswer = surveyAnswers?.find((answer) => answer.uid === uid);
    if (availableAnswer?.answer) {
      const { answer, description } = availableAnswer;
      setDescriptionText(description);
      setAudioAnswer(answer);
      if (typeof answer === 'string') {
        setAudioUrl(answer);
      } else {
        convertFileToBlob(answer);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uid]);

  useEffect(() => {
    if (!recorderError && status === 'recording') {
      setTimerRunning(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, recorderError]);

  useEffect(() => {
    if (mediaBlobUrl) {
      convertBlobToFile(mediaBlobUrl);
      setDescriptionModal(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mediaBlobUrl]);

  return (
    <div>
      <NavBar title={title} />
      {recordingError && <Alert severity="error" message={recordingError} />}
      <div className={classes.audioWrapper}>
        <img src={audioWave} className={classes.audioWave} alt="Audio Wave" />
        {audioAnswer ? (
          <>
            {isPlaying ? (
              <IconButton onClick={pauseRecorderAudio}>
                <img src={pauseButton} alt="Pause Button" className={classes.pauseButton} />
              </IconButton>
            ) : (
              <IconButton onClick={playRecorderAudio}>
                <img src={playButton} alt="Play Button" />
              </IconButton>
            )}
            <Button onClick={handleDiscard} className={classes.recordButton}>
              Discard & Record Again
            </Button>
            {descriptionText && (
              <div className={classes.descriptionWrapper}>
                <div className={classes.descriptionText}>{descriptionText}</div>
                <Button onClick={handleDescriptionModal} className={classes.editDescriptionButton}>
                  Edit description
                </Button>
              </div>
            )}
            {!descriptionText && (
              <Button onClick={handleDescriptionModal} className={classes.editDescriptionButton}>
                Add description
              </Button>
            )}
          </>
        ) : (
          <>
            <Timer isRunning={isTimerRunning} />
            {isTimerRunning ? (
              <Button onClick={stopRecordingAudio} className={classes.recordButton}>
                Stop
              </Button>
            ) : (
              <>
                <Button onClick={startRecordingAudio} className={classes.recordButton}>
                  Record
                </Button>
              </>
            )}
          </>
        )}
        {error && <Alert severity="error" message={error} />}
        {recorderError && <Alert severity="error" message={recorderError} />}
      </div>
      <ProgressButton
        nextButtonDisabled={getQuestionRequired(isRequired, isTimerRunning || !audioAnswer)}
        nextButtonClicked={handleAnswer}
        loading={loading}
      />
      <audio src={audioUrl} controls ref={audioRef} className={classes.audioInput} onEnded={handleStopPlaying} />
      <DescriptionModal
        isOpen={isDescriptionModal}
        handleClose={handleDescriptionModal}
        handleDescriptionSave={handleDescriptionSave}
        text={descriptionText}
        title={descriptionText ? 'Edit Description' : 'Add Description'}
      />
    </div>
  );
};

export default AudioQuestion;
