import React, { useState, useRef, useEffect } from 'react';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DayjsUtils from '@date-io/dayjs';
import dayjs from 'dayjs';

import NavBar from 'components/NavBar';
import ProgressButton from 'components/ProgressButton';
import Alert from 'components/Alert';
import useJournalStore from 'store/journalStore';
import { getQuestionRequired } from 'helper/helper';

import useStyles from './styles';

const DateQuestion = ({ title, uid, isRequired }) => {
  const classes = useStyles();
  const datePicker = useRef();
  const { updateAnswerByUID, error, surveyAnswers } = useJournalStore();
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState(new Date());
  const [isError, setError] = useState('');

  const handleAnswer = async () => {
    if (!!isError) return false;
    if (!isRequired && !date) return true;
    setLoading(true);
    const formattedDate = dayjs(date).format('MM/DD/YYYY');
    const answerFormData = new FormData();
    answerFormData.append('Uid', uid);
    answerFormData.append('AnswerText', formattedDate);
    const resp = await updateAnswerByUID(answerFormData);
    setLoading(false);
    return resp;
  };

  const handleDateChange = (date) => setDate(date);

  const handleError = (error) => setError(error);

  useEffect(() => {
    const availableAnswer = surveyAnswers?.find((answer) => answer.uid === uid);
    if (!availableAnswer?.answer) return;
    const { answer } = availableAnswer;
    if (answer) {
      setDate(new Date(answer));
    } else {
      setDate(new Date());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uid]);

  return (
    <>
      <NavBar title={title} />
      <div className={classes.dateWrapper}>
        <div className={classes.datePicker} ref={datePicker}>
          <MuiPickersUtilsProvider utils={DayjsUtils}>
            <KeyboardDatePicker
              disableToolbar
              autoFocus
              variant="inline"
              format="MM/DD/YYYY"
              margin="normal"
              id="date-picker-inline"
              value={date}
              onError={handleError}
              onChange={handleDateChange}
              classes={{
                root: classes.dateInput,
              }}
              PopoverProps={{
                anchorEl: datePicker.current,
                anchorOrigin: {
                  horizontal: 150,
                  vertical: 'bottom',
                },
              }}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </MuiPickersUtilsProvider>
        </div>
      </div>
      {error && <Alert severity="error" message={error} />}
      <ProgressButton
        nextButtonDisabled={getQuestionRequired(isRequired, !date || !!isError)}
        nextButtonClicked={handleAnswer}
        loading={loading}
      />
    </>
  );
};

export default DateQuestion;
