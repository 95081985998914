import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  checkboxQuestionWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 50,
    paddingBottom: 90,
    width: '100%',
  },
  checkBoxQuestion: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 90,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
    },
  },
}));

export default useStyles;
