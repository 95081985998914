import axios from 'axios';
import { Storage } from '@capacitor/storage';

export const setToken = async (key, value) => {
  await Storage.set({
    key: key,
    value: value,
  });
};

let subscribers = [];
let isAlreadyFetchingAccessToken = false;

function onAccessTokenFetched(access_token) {
  subscribers.forEach((callback) => callback(access_token));
  subscribers = [];
}

function addSubscriber(callback) {
  subscribers.push(callback);
}

const resetTokenAndReattemptRequest = async (error) => {
  try {
    const { response: errorResponse } = error;
    const lastRequest = errorResponse.config;
    lastRequest._retry = true;
    const refreshToken = await getValueFromStorage('refreshToken');
    const retryOriginalRequest = new Promise((resolve) => {
      addSubscriber((access_token) => {
        errorResponse.config.headers.Authorization = 'Bearer ' + access_token;
        resolve(axios(errorResponse.config));
      });
    });
    if (!isAlreadyFetchingAccessToken) {
      isAlreadyFetchingAccessToken = true;
      const resp = await Axios.post('/auth/refresh-token', { refreshToken });
      if (resp) {
        const { data } = resp;
        await setToken('jwtToken', data.jwtToken);
        await setToken('refreshToken', data.refreshToken);
        Axios.defaults.headers.common['Authorization'] = 'Bearer ' + data.jwtToken;
        lastRequest.headers['Authorization'] = 'Bearer ' + data.jwtToken;
        isAlreadyFetchingAccessToken = false;
        onAccessTokenFetched(data.jwtToken);
      } else {
        await clearStorage();
        window.location.reload();
        return;
      }
    }
    return retryOriginalRequest;
  } catch (err) {
    await clearStorage();
    window.location.reload();
  }
};

const getBaseURL = () => {
  switch (process.env.REACT_APP_ENV) {
    case 'production':
      return process.env.REACT_APP_BASE_API_PRODUCTION;
    case 'staging':
      return process.env.REACT_APP_BASE_API_STAGING;
    case 'develop':
    default:
      return process.env.REACT_APP_BASE_API_DEVELOP;
  }
};

const Axios = axios.create({
  baseURL: getBaseURL(),
});

export const clearStorage = async () => {
  await Storage.clear();
};

export const getValueFromStorage = async (name) => {
  const { value } = await Storage.get({ key: name });
  return value;
};

Axios.interceptors.request.use(
  async (config) => {
    const token = await getValueFromStorage('jwtToken');
    if (token) config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  (error) => Promise.reject(error),
);

Axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error && error.response && error.response.status === 401) {
      return resetTokenAndReattemptRequest(error);
    }
    return Promise.reject(error);
  },
);

export default Axios;
