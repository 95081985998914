import { makeStyles } from '@material-ui/core';
import env from 'env';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: env.isMobile ? 30 : 0,
  },
  progressWrapper: {
    padding: theme.spacing(1, 2),
    backgroundColor: '#EDF8F5',
    borderRadius: 10,
    color: theme.palette.success.dark,
    fontWeight: 700,
  },
}));

export default useStyles;
