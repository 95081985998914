import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 15,
    width: 'calc(100% - 4px)',
    color: theme.palette.text.primary,
    marginBottom: 20,
    background: theme.palette.common.white,
    border: '2px solid #DDEAE0',
    boxShadow: '0px 5px 18px rgba(186, 186, 186, 0.25)',
    cursor: 'pointer',
    '&:last-child': {
      marginBottom: 0,
    },
  },
  titleWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '11px 20px 18px 25px',
  },
  title: {
    display: 'flex',
    flexDirection: 'column',
    fontFamily: 'Montserrat',
    marginRight: 5,
    color: theme.palette.primary.main,
  },
  dueDate: {
    color: theme.palette.error.dark,
    fontSize: 13,
    fontWeight: 'bold',
    letterSpacing: 1,
  },
  menuIcon: {
    padding: 0,
  },
  dateWrapper: {
    display: 'flex',
    marginTop: 5,
    color: theme.palette.text.hint,
    fontSize: 13,
  },
  surveyName: {
    fontFamily: 'Montserrat',
    fontSize: 16,
    lineHeight: '20px',
    color: '#282828',
    marginTop: 6,
  },
  surveyDescription: {
    marginLeft: theme.spacing(1),
  },
  dueDateWrapper: {
    padding: '8px 25px',
    background: '#DDEAE0',
    borderBottomLeftRadius: 12,
    borderBottomRightRadius: 12,
  },
  menu: {
    boxShadow: '0px 4px 13px #DADADA',
    borderRadius: 10,
  },
  menuItems: {
    fontFamily: 'Montserrat',
    fontSize: 16,
    width: 150,
    color: theme.palette.text.primary,
  },
}));

export default useStyles;
