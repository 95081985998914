import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  radioGroupWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 50,
    paddingBottom: 90,
    width: '100%',
  },
  radio: {
    width: '100%',
    display: 'flex',
    paddingLeft: 90,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
    },
  },
  radioGroupRoot: {
    width: '100%',
  },
}));

export default useStyles;
