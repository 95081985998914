import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  textWrapper: {
    fontFamily: 'Montserrat',
    fontWeight: 300,
    fontSize: 16,
    color: theme.palette.text.secondary,
    display: 'flex',
    flexDirection: 'column',
  },
  downloadButton: {
    maxWidth: 'fit-content',
    color: theme.palette.common.white,
    fontFamily: 'Montserrat',
    marginTop: theme.spacing(1),
  },
}));

export default useStyles;
