import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: '100%',
    paddingBottom: 90,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  editorWrapperClassName: {
    border: `1px solid ${theme.palette.primary.dark}`,
    borderRadius: 10,
    margin: '20px 0',
    padding: '0 18px 18px 18px',
    height: 600,
    [theme.breakpoints.down('xs')]: {
      height: 530,
    },
  },
  editorClassName: {
    padding: '19px 50px 50px 50px',
    fontFamily: 'Montserrat',
    fontWeight: 300,
    fontSize: 18,
    height: 'auto',
    maxHeight: 555,
    color: theme.palette.text.primary,
    overflowY: 'auto',
    [theme.breakpoints.down('xs')]: {
      padding: 20,
      maxHeight: 485,
    },
  },
  toolBarClassName: {
    display: 'flex',
    justifyContent: 'center',
    border: 0,
    borderBottom: `1px solid ${theme.palette.primary.dark}`,
    padding: 10,
    margin: 0,
  },
  toolBarButtonClassName: {
    border: 0,
  },
}));

export default useStyles;
