import { makeStyles } from '@material-ui/core/styles';

const drawerWidth = 200;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  drawerContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    padding: '68px 0 29px 0',
    background: theme.palette.primary.main,
  },
  list: {
    padding: 0,
  },
  listItemText: {
    fontSize: 18,
    marginLeft: 10,
    fontFamily: 'Montserrat',
    color: theme.palette.common.white,
  },
  userProfile: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    cursor: 'pointer',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  userImage: {
    width: 50,
    height: 50,
    borderRadius: 14,
    marginBottom: 20,
  },
  userImageMobile: {
    width: 50,
    height: 50,
    borderRadius: 14,
  },
  userName: {
    fontWeight: 300,
    fontSize: 16,
    color: theme.palette.common.white,
    fontFamily: 'Montserrat',
    padding: theme.spacing(0, 2),
    textAlign: 'center',
  },
  appBar: {
    background: '#FFFFFF',
    boxShadow: 'none',
    padding: `env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left)`,
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      display: 'none',
    },
  },
  toolBar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  menuButton: {
    borderRadius: 15,
    marginRight: theme.spacing(2),
    marginLeft: 0,
    padding: 5,
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  hamburderMenu: {
    maxWidth: 35,
  },
  drawerPaper: {
    width: drawerWidth,
    background: theme.palette.primary.main,
  },
  content: {
    flexGrow: 1,
    padding: '24px 0',
    [theme.breakpoints.down('sm')]: {
      padding: 24,
    },
  },
  sidebarLinks: {
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
    padding: '10px 28px',
    marginBottom: 40,

    '&:last-child': {
      marginBottom: 0,
    },
  },
  activeLink: {
    fontWeight: 'bold',
  },
  progressWrapper: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
  },
  logoutButton: {
    marginTop: 10,
    fontFamily: 'Montserrat',
    fontStyle: 'italic',
    color: theme.palette.common.white,
  },
}));

export default useStyles;
