import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  textWrapper: {
    fontFamily: 'Montserrat',
    fontWeight: 300,
    fontSize: 16,
    color: theme.palette.text.secondary,
  },
  editorWrapperClassName: {
    border: 0,
  },
  editorClassName: {
    fontFamily: 'Montserrat',
    fontWeight: 300,
    fontSize: 16,
    height: 'auto',
    maxHeight: 250,
    color: theme.palette.text.primary,
    overflowY: 'auto',
  },
  toolBarClassName: {
    display: 'none',
  },
  toolBarButtonClassName: {
    border: 0,
  },
}));

export default useStyles;
