export const menuItems = [
  {
    id: 1,
    title: 'Newest to oldest',
  },
  {
    id: 2,
    title: 'Oldest to newest',
  },
  {
    id: 3,
    title: 'Group by activity',
  },
  {
    id: 4,
    title: 'Group by provider',
  },
  {
    id: 5,
    title: 'Group by type',
  },
];
