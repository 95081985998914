import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  dialogWrapper: {
    borderRadius: 15,
    padding: theme.spacing(3, 4),
    maxWidth: 451,
    width: '100%',
  },
  dialogTitle: {
    color: theme.palette.text.primary,
    fontSize: 20,
    textAlign: 'center',
    fontWeight: 600,
  },
  dialogDescription: {
    fontSize: 16,
    fontWeight: 300,
    textAlign: 'center',
    color: theme.palette.common.black,
    marginTop: theme.spacing(2),
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  buttonStyles: {
    borderRadius: 10,
    padding: '14px 22px',
    fontSize: 18,
    lineHeight: 1,
  },
  primaryButton: {
    color: theme.palette.common.white,
  },
  linkButton: {
    color: theme.palette.primary.main,
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(1),
    },
  },
}));

export default useStyles;
