import Home from 'assets/icons/home.svg';
import Feed from 'assets/icons/feed.svg';
import Inprogress from 'assets/icons/inprogress.svg';

export const sidebarContent = [
  {
    title: 'Home',
    icon: Home,
    path: '/',
  },
  {
    title: 'Feed',
    icon: Feed,
    path: '/feed',
  },
  {
    title: 'In Progress',
    icon: Inprogress,
    path: '/survey',
  },
];
