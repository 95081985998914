import React, { useState, useEffect } from 'react';
import { Button } from '@material-ui/core';
import { useParams, useHistory } from 'react-router-dom';

import useJournalStore from 'store/journalStore';

import useStyles from './styles';

const ProgressButton = ({ nextButtonDisabled, nextButtonClicked, loading = false }) => {
  const classes = useStyles();
  const history = useHistory();
  const { surveyQuestions, doSaveAndExit } = useJournalStore();
  const { questionId, surveyId } = useParams();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [saveAndExitButtonLoading, setSaveAndExitButtonLoading] = useState(false);

  const handleNextClick = async () => {
    const resp = await nextButtonClicked();
    if (resp) {
      if (currentIndex < surveyQuestions.length - 1) {
        const nextQuestionUid = surveyQuestions[currentIndex + 1].uid;
        history.push(`/survey/${surveyId}/${nextQuestionUid}`);
      } else if (currentIndex === surveyQuestions.length - 1) {
        history.push(`/journal/${surveyId}/completed`);
      }
    }
  };

  const saveSurvey = async () => {
    const surveyDetails = {
      surveyResponseId: +surveyId,
      uid: questionId,
    };
    const isSaved = await doSaveAndExit(surveyDetails);
    if (isSaved) {
      history.push('/');
    }
  };

  const handleSaveAndExit = async () => {
    setSaveAndExitButtonLoading(true);
    if (nextButtonDisabled) {
      await saveSurvey();
    } else {
      const resp = await nextButtonClicked();
      if (resp) {
        await saveSurvey();
      }
    }
    setSaveAndExitButtonLoading(false);
  };

  useEffect(() => {
    if (surveyQuestions.length) {
      const index = surveyQuestions.findIndex((question) => question.uid === questionId);
      setCurrentIndex(index);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionId]);

  return (
    <div className={classes.wrapper}>
      <Button
        color="primary"
        size="large"
        className={classes.saveAndExitButton}
        onClick={handleSaveAndExit}
        disabled={saveAndExitButtonLoading}
      >
        Save and Exit
      </Button>
      <Button
        variant="contained"
        size="large"
        color="primary"
        disabled={nextButtonDisabled || loading}
        className={classes.nextButton}
        onClick={handleNextClick}
        type="submit"
      >
        {loading ? 'Saving' : 'Next'}
      </Button>
    </div>
  );
};

export default ProgressButton;
