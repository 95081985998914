import React, { useState } from 'react';
import { Button, TextField } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import useSignUpStore from 'store/signupStore';
import Alert from 'components/Alert';

import useStyles from './styles';

const LoginForm = () => {
  const classes = useStyles();
  const history = useHistory();
  const { error, doLogin, resetState, setAuthentication } = useSignUpStore();
  const [loading, setLoading] = useState(false);
  const [loginDetails, setLoginDetails] = useState({
    emailOrUsernameOrPhone: '',
    password: '',
  });

  const handleTextChange = ({ target: { name, value } }) => {
    setLoginDetails({
      ...loginDetails,
      [name]: value,
    });
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const token = await doLogin(loginDetails);
      if (token) {
        resetState();
        setAuthentication(token);
        history.push('/');
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleLogin}>
      <div className={classes.loginForm}>
        <div className={classes.title}>Login</div>
        <div className={classes.textFieldWrapper}>
          <div className={classes.label}>Your Email Address *</div>
          <TextField
            fullWidth
            name="emailOrUsernameOrPhone"
            value={loginDetails.emailOrUsernameOrPhone}
            variant="outlined"
            placeholder="sample@email.com"
            onChange={handleTextChange}
            classes={{
              root: classes.textField,
            }}
            inputProps={{
              pattern: '[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}',
              required: 'required',
              'data-testid': 'login-email',
            }}
          />
        </div>
        <div className={classes.textFieldWrapper}>
          <div className={classes.label}>Password *</div>
          <TextField
            fullWidth
            name="password"
            type="password"
            value={loginDetails.password}
            variant="outlined"
            placeholder="********"
            onChange={handleTextChange}
            classes={{
              root: classes.textField,
            }}
            inputProps={{
              required: 'required',
              'data-testid': 'login-password',
            }}
          />
        </div>
        {error && <Alert severity="error" message={error} />}
        <Button type="submit" color="primary" variant="contained" className={classes.nextButton} disabled={loading}>
          Next
        </Button>
      </div>
    </form>
  );
};

export default LoginForm;
