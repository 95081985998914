import React, { useState, useEffect } from 'react';
import { FormControlLabel, Checkbox } from '@material-ui/core';

import NavBar from 'components/NavBar';
import ProgressButton from 'components/ProgressButton';
import Alert from 'components/Alert';
import useJournalStore from 'store/journalStore';
import { getQuestionRequired } from 'helper/helper';

import useStyles from './styles';

const CheckBoxLabelQuestion = ({ title, uid, link, isRequired }) => {
  const classes = useStyles();
  const { updateAnswerByUID, error, surveyAnswers } = useJournalStore();
  const [loading, setLoading] = useState(false);
  const [isChecked, setChecked] = useState(false);
  const [didOnChangeTriggered, setOnChangeTriggered] = useState(false);

  const handleAnswer = async () => {
    if (!isRequired && !didOnChangeTriggered) return true;
    setLoading(true);
    const answerFormData = new FormData();
    answerFormData.append('Uid', uid);
    answerFormData.append('AnswerText', isChecked);
    const resp = await updateAnswerByUID(answerFormData);
    setLoading(false);
    setChecked(false);
    return resp;
  };

  const handleCheck = ({ target: { checked } }) => {
    setOnChangeTriggered(true);
    setChecked(checked);
  };

  useEffect(() => {
    const availableAnswer = surveyAnswers?.find((answer) => answer.uid === uid);
    if (!availableAnswer?.answer) return;
    const { answer } = availableAnswer;
    const isAnswerChecked = answer === 'true' || answer === '1';
    setChecked(isAnswerChecked);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uid]);

  return (
    <>
      <NavBar />
      <div className={classes.checkboxWrapper}>
        <FormControlLabel
          control={
            <Checkbox
              checked={isChecked}
              classes={{
                checked: classes.checked,
                root: classes.root,
              }}
            />
          }
          classes={{
            root: classes.formControlLabelRoot,
          }}
          onChange={handleCheck}
        />
        <a href={link} target="_blank" rel="noreferrer">
          {title}
        </a>
      </div>
      {error && <Alert severity="error" message={error} />}
      <ProgressButton
        nextButtonDisabled={getQuestionRequired(isRequired, false)}
        nextButtonClicked={handleAnswer}
        loading={loading}
      />
    </>
  );
};

export default CheckBoxLabelQuestion;
