import React from 'react';
import Sidebar from 'components/Sidebar';

const Page = ({ Component }) => (
  <Sidebar>
    <Component />
  </Sidebar>
);

export default Page;
