import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    color: theme.palette.text.primary,
    padding: '0 95px',
    marginTop: 59,
    [theme.breakpoints.down('xs')]: {
      padding: 0,
    },
  },
  entryCardWrapper: {
    maxWidth: 450,
    marginTop: 25,
  },
}));

export default useStyles;
