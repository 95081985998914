import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core';
import theme from './theme';
import SafeArea from 'react-safe-area-component';
import { Provider, ErrorBoundary } from '@rollbar/react';

const rollbarConfig = {
  accessToken: process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN,
  environment: process.env.REACT_APP_ENV,
};

ReactDOM.render(
  <Provider config={rollbarConfig}>
    <ErrorBoundary>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <React.StrictMode>
            <SafeArea top bottom>
              <App />
            </SafeArea>
          </React.StrictMode>
        </BrowserRouter>
      </ThemeProvider>
    </ErrorBoundary>
  </Provider>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
