export const generateSurveyAnswer = (uid, uploadedFile, descriptionText) => {
  const answerFormData = new FormData();
  answerFormData.append('Uid', uid);
  if (descriptionText) {
    answerFormData.append('description', descriptionText);
  }
  if (typeof uploadedFile === 'string') {
    answerFormData.append('AnswerText', uploadedFile);
  } else {
    answerFormData.append('file', uploadedFile);
  }
  return answerFormData;
};
