import React, { useRef, useState, useEffect } from 'react';
import { Button, Dialog, Typography } from '@material-ui/core';
import Croppie from 'croppie';
import cs from 'classnames';

import add from 'assets/icons/add.svg';
import minus from 'assets/icons/minus.svg';
import useSignUpStore from 'store/signupStore';
import Alert from 'components/Alert';
import WebCamera from 'components/WebCamera';
import userProfileStore from 'store/userProfileStore';

import useStyles from './styles';
import 'croppie/croppie.css';

const ImageUpload = ({ updateStep }) => {
  const classes = useStyles();
  const captureInputRef = useRef();
  const uploadInputRef = useRef();
  const { doUploadImage, userProfilePhoto, error, resetState } = useSignUpStore();
  const [isUploadModalOpen, setUploadModal] = useState(false);
  const [isCropped, setCropped] = useState(false);
  const [imageZoom, setImageZoom] = useState(0);
  const [minZoom, setMinZoom] = useState(0);
  const [croppie, setCroppie] = useState(null);
  const [uploadedImage, setUploadedImage] = useState(userProfilePhoto);
  const [imageName, setImageName] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isCameraOpen, setCameraOpen] = useState(false);
  const [currentVideoStream, setCurrentVideoStream] = useState();
  const {
    userData: { firstName, lastName },
  } = userProfileStore();

  const crop = () => {
    const croppieElement = new Croppie(document.getElementById('crop'), {
      viewport: {
        width: 140,
        height: 140,
      },
      boundary: {
        width: 160,
        height: 160,
      },
      customClass: 'cropper',
    });
    setCroppie(croppieElement);
  };

  const handleUploadFile = () => {
    uploadInputRef.current.click();
  };

  const handleUploadModal = () => {
    setUploadModal(!isUploadModalOpen);
  };

  const handleZoomIncrease = () => {
    if (imageZoom < 1.5) {
      setImageZoom(imageZoom + 0.1);
    }
  };

  const handleZoomDecrease = () => {
    if (imageZoom >= minZoom) {
      setImageZoom(imageZoom - 0.1);
    }
  };

  const handleSliderChange = (event) => {
    const {
      target: { min },
    } = event;
    const currentZoom = croppie.get().zoom;
    setImageZoom(currentZoom);
    setMinZoom(min);
  };

  const handleCrop = (event) => {
    const file = event.target.files[0];
    setImageName(file.name);
    const reader = new FileReader();
    reader.onload = async (upload) => {
      const result = await upload.target.result;
      setUploadedImage(result);
    };
    reader.readAsDataURL(file);
  };

  const handleCropSave = () => {
    croppie.result('base64').then((blob) => {
      setUploadModal(false);
      setCropped(true);
      setTimeout(() => {
        setUploadedImage(blob);
      }, 500);
    });
    setUploadModal(false);
  };

  const handleRetakePhoto = () => {
    setMinZoom(0);
    setImageZoom(0);
    setUploadedImage(null);
    setCroppie(null);
    setCropped(false);
    setCameraOpen(false);
    uploadInputRef.current.value = '';
    resetState();
  };

  const handleUploadImage = async () => {
    try {
      setLoading(true);
      const fileName = imageName ? imageName : `${firstName} ${lastName}.jpg`;
      const resp = await doUploadImage(fileName, uploadedImage);
      if (resp) {
        resetState();
        updateStep();
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const startCamera = () => {
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then((stream) => {
        stream.getTracks().forEach((track) => track.stop());
        setCameraOpen(true);
      })
      .catch(() => {
        captureInputRef.current.click();
      });
  };

  const handleCameraToggle = () => setCameraOpen(!isCameraOpen);

  useEffect(() => {
    if (uploadedImage && !isCropped) {
      handleUploadModal();
    }
    if (isCropped) {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadedImage, isCropped]);

  useEffect(() => {
    if (isUploadModalOpen) {
      setTimeout(() => {
        crop();
      }, 100);
    }
  }, [isUploadModalOpen]);

  useEffect(() => {
    if (croppie) {
      croppie.setZoom(imageZoom);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageZoom]);

  useEffect(() => {
    if (croppie) {
      croppie.bind({ url: uploadedImage }).then(() => {
        croppie.setZoom(imageZoom);
      });
      croppie.result('blob').then(() => {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [croppie]);

  useEffect(() => {
    const crSlider = document?.getElementsByClassName('cr-slider')[0];
    crSlider?.addEventListener('change', handleSliderChange);

    return () => {
      localStorage.removeItem('routeAt');
      crSlider?.removeEventListener('change', handleSliderChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [croppie]);

  useEffect(() => {
    if (!isCameraOpen && currentVideoStream) {
      currentVideoStream.forEach((current) => current.stop());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCameraOpen]);

  return (
    <div className={classes.wrapper}>
      {uploadedImage ? (
        <>
          <div className={classes.imageWrapper}>
            <img src={uploadedImage} className={classes.userImage} alt="User" />
          </div>
          <Button className={classes.retakeButton} color="primary" onClick={handleRetakePhoto}>
            Discard & Retake
          </Button>
        </>
      ) : (
        <>
          <Button className={classes.nextButton} color="primary" onClick={handleUploadFile} variant="contained">
            Upload Photo
          </Button>
          <Button className={classes.uploadButton} color="primary" onClick={startCamera}>
            Open Camera
          </Button>
        </>
      )}
      {error && <Alert severity="error" message={error} />}
      <div className={classes.buttonWrapper}>
        <Button
          type="submit"
          color="primary"
          variant="contained"
          className={cs(classes.nextButton, {
            [classes.isError]: error,
          })}
          onClick={handleUploadImage}
          disabled={!uploadedImage || loading}
        >
          Next
        </Button>
        <div className={classes.skipNow} onClick={updateStep}>
          Skip for now
        </div>
      </div>
      <input
        type="file"
        accept="image/*"
        capture="user"
        className={classes.inputFile}
        data-testid="capture-input"
        ref={captureInputRef}
        onChange={handleCrop}
      />
      <input
        type="file"
        accept="image/*"
        ref={uploadInputRef}
        className={classes.inputFile}
        onChange={handleCrop}
        data-testid="image-upload"
      />
      <Dialog
        open={isUploadModalOpen}
        onClose={handleUploadModal}
        classes={{
          paper: classes.uploadPhotoDialog,
        }}
      >
        <div className={classes.uploadPhotoModal}>
          <Typography variant="subtitle1">Crop Photo</Typography>
          <div id="crop" className={classes.cropperWrapper}>
            <div className={classes.minusButon} onClick={handleZoomDecrease}>
              <img src={minus} alt="Minus" />
            </div>
            <div className={classes.addButton} onClick={handleZoomIncrease}>
              <img src={add} alt="Add" />
            </div>
          </div>
          <Button color="primary" variant="contained" fullWidth className={classes.takePhoto} onClick={handleCropSave}>
            Crop & Save
          </Button>
        </div>
      </Dialog>
      <Dialog
        open={isCameraOpen}
        onClose={handleCameraToggle}
        maxWidth="lg"
        classes={{
          paper: classes.uploadPhotoDialog,
        }}
      >
        {isCameraOpen && (
          <WebCamera
            setCapturedData={setUploadedImage}
            handleCameraOpen={handleCameraToggle}
            setVideoStream={setCurrentVideoStream}
          />
        )}
      </Dialog>
    </div>
  );
};

export default ImageUpload;
