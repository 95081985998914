import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: 320,
    alignItems: 'center',
  },
  introImg: {
    width: 280,
    height: 280,
  },
  title: {
    fontFamily: 'Montserrat',
    fontSize: 20,
    textAlign: 'center',
    color: theme.palette.text.primary,
    marginTop: 42,
  },
  resendCode: {
    fontFamily: 'Montserrat',
    fontSize: 12,
    textDecorationLine: 'underline',
    color: theme.palette.primary.main,
    margin: '9px 0 31px',
    cursor: 'pointer',
  },
  otpField: {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 10,
    maxWidth: 260,
    overflow: 'hidden',

    '& .MuiOutlinedInput-root': {
      height: 45,
    },

    '& .MuiOutlinedInput-input': {
      padding: '6px 0 7px',
    },

    '& .MuiInputBase-input': {
      textAlign: 'center',
      fontFamily: 'Montserrat',
      fontWeight: 600,
      fontSize: 26,
      color: theme.palette.warning.dark,
    },

    '& input': {
      '&::placeholder': {
        fontSize: 20,
      },
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },

    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent',
    },

    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent',
    },
  },
  setupButton: {
    background: theme.palette.primary.dark,
    borderRadius: 10,
    color: 'white',
    width: 260,
    marginTop: 15,
  },
  haveAccount: {
    fontFamily: 'Montserrat',
    fontSize: 12,
    color: theme.palette.text.secondary,
    marginTop: 36,
  },
  isError: {
    marginTop: 10,
  },
}));

export default useStyles;
