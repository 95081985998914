import React from 'react';
import { CircularProgress as CircularProgressAtom } from '@material-ui/core';

import useStyles from './styles';

const CircularProgress = () => {
  const classes = useStyles();
  return (
    <div className={classes.loading}>
      <CircularProgressAtom />
    </div>
  );
};

export default CircularProgress;
