import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: '100%',
  },
  dropdownWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 50,
    paddingBottom: 90,
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      border: `2px solid ${theme.palette.primary.dark}`,
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: 10,
    },
  },
  dropdown: {
    width: '100%',
    display: 'flex',
    paddingLeft: 90,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.primary.dark,
    },
  },
  formControlRoot: {
    width: '100%',
    maxWidth: 540,
    borderRadius: 10,
  },
  select: {
    fontFamily: 'Montserrat',
  },
  list: {
    padding: '10px 22px',
    fontFamily: 'Montserrat',
    borderBottom: `1px solid ${theme.palette.text.disabled}`,
    '&:last-child': {
      borderBottom: 0,
    },
  },
}));

export default useStyles;
