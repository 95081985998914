import React from 'react';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import partyPopper1 from 'assets/icons/partyPopper1.png';
import confetti from 'assets/icons/confetti.gif';
import useSignUpStore from 'store/signupStore';

import useStyles from './styles';
import { getValueFromStorage } from 'http/request';

const SignUpComplete = () => {
  const classes = useStyles();
  const history = useHistory();
  const { setAuthentication } = useSignUpStore();

  const getStarted = async () => {
    const getToken = await getValueFromStorage('jwtToken');
    setAuthentication(getToken);
    history.push('/');
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.title}>You’re all set!</div>
      <img src={confetti} alt="Confetti" className={classes.confetti} />
      <img src={partyPopper1} alt="Party Popper 1" />
      <Button color="primary" variant="contained" className={classes.startButton} onClick={getStarted}>
        Get Started
      </Button>
    </div>
  );
};

export default SignUpComplete;
