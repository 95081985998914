import React, { useRef, useState, useEffect } from 'react';
import { Button, Dialog, Typography } from '@material-ui/core';

import NavBar from 'components/NavBar';
import ProgressButton from 'components/ProgressButton';
import DescriptionModal from 'components/DescriptionModal';
import Alert from 'components/Alert';
import useJournalStore from 'store/journalStore';
import { generateSurveyAnswer } from 'helper/formData';
import { getFileUploadedRequired } from 'helper/helper';

import useStyles from './styles';

const FileUpload = ({ title, description, uid, isRequired }) => {
  const classes = useStyles();
  const uploadInputRef = useRef();
  const { updateAnswerByUID, error, surveyAnswers } = useJournalStore();
  const [isDescriptionModal, setDescriptionModal] = useState(false);
  const [descriptionText, setDescriptionText] = useState(description);
  const [uploadImageModal, setUploadImageModal] = useState(false);
  const [uploadedFile, setUploadedFile] = useState('');
  const [loading, setLoading] = useState(false);
  const [fileName, setFileName] = useState('');

  const handleDiscardFile = () => {
    setUploadedFile('');
    setFileName('');
    uploadInputRef.current.value = '';
    setDescriptionText('');
  };

  const handleDescriptionModal = () => {
    setDescriptionModal(!isDescriptionModal);
  };

  const handleDescriptionSave = (value) => {
    setDescriptionText(value);
  };

  const handleImageUpload = () => {
    uploadInputRef.current.click();
  };

  const handleAnswer = async () => {
    const valid = getFileUploadedRequired(isRequired, uploadedFile);
    if (!valid) return true;
    setLoading(true);
    const answerFormData = await generateSurveyAnswer(uid, uploadedFile, descriptionText);
    const resp = await updateAnswerByUID(answerFormData, true);
    setLoading(false);
    return resp;
  };

  const handleUploadChange = (event) => {
    const file = event.target.files[0];
    setUploadedFile(file);
    setFileName(file.name);
    setUploadImageModal(false);
    setDescriptionModal(true);
  };

  const handleUploadModal = () => {
    setUploadImageModal(!uploadImageModal);
  };

  useEffect(() => {
    setUploadedFile('');
    setDescriptionText('');
    setFileName('');
    const availableAnswer = surveyAnswers?.find((answer) => answer.uid === uid);
    if (availableAnswer?.answer) {
      const { answer, description } = availableAnswer;
      setUploadedFile(answer);
      setDescriptionText(description);
      if (typeof answer === 'string') {
        const name = answer?.split('/');
        const decodedName = decodeURIComponent(name[name.length - 1]);
        setFileName(decodedName);
      } else {
        setFileName(answer.name);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uid]);

  return (
    <>
      <NavBar title={title} description={description} />
      <div className={classes.photoWrapper}>
        <div>Supported file types are .pdf,.txt,.docx,.png,.jpg,.jpeg,.mp4,.mp3</div>
        {fileName && <div className={classes.fileName}>{fileName}</div>}
        {uploadedFile ? (
          <Button className={classes.uploadButton} onClick={handleDiscardFile}>
            Discard & Upload New File
          </Button>
        ) : (
          <Button className={classes.uploadButton} onClick={handleUploadModal}>
            Upload File
          </Button>
        )}
        {descriptionText && (
          <div className={classes.descriptionWrapper}>
            <div className={classes.descriptionText}>{descriptionText}</div>
            <Button onClick={handleDescriptionModal} className={classes.editDescriptionButton}>
              Edit description
            </Button>
          </div>
        )}
        {!descriptionText && uploadedFile && (
          <Button onClick={handleDescriptionModal} className={classes.editDescriptionButton}>
            Add description
          </Button>
        )}
        {error && <Alert severity="error" message={error} />}
      </div>
      <ProgressButton
        nextButtonDisabled={!getFileUploadedRequired(isRequired, uploadedFile)}
        nextButtonClicked={handleAnswer}
        loading={loading}
      />
      <input
        type="file"
        accept="*/*"
        ref={uploadInputRef}
        className={classes.inputFile}
        onChange={handleUploadChange}
      />
      <DescriptionModal
        isOpen={isDescriptionModal}
        handleClose={handleDescriptionModal}
        handleDescriptionSave={handleDescriptionSave}
        text={descriptionText}
        title={descriptionText ? 'Edit Description' : 'Add Description'}
      />
      <Dialog
        open={uploadImageModal}
        onClose={handleUploadModal}
        classes={{
          paper: classes.modal,
        }}
      >
        <div className={classes.modalContent}>
          <Typography variant="subtitle1">Upload File</Typography>
          <Button
            className={classes.selectImageButton}
            color="primary"
            variant="contained"
            fullWidth
            onClick={handleImageUpload}
          >
            Select File
          </Button>
        </div>
      </Dialog>
    </>
  );
};

export default FileUpload;
