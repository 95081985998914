import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  checkboxWrapper: {
    borderRadius: 12,
    border: `2px solid ${theme.palette.text.disabled}`,
    maxWidth: 650,
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    marginLeft: 90,
    marginTop: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },
    '& a': {
      color: theme.palette.primary.main,
      textDecoration: 'none',
      maxHeight: 450,
      overflow: 'auto',
      margin: '12px 0',
    },
  },
  formControlLabelRoot: {
    margin: '10px 18px',
    alignItems: 'flex-start',
    '& .MuiTypography-root': {
      marginLeft: 18,
    },
  },
  checked: {
    color: `${theme.palette.primary.dark} !important`,
    padding: 0,
    borderRadius: 3,
    background: `transparent !important`,
  },
  root: {
    padding: 0,
    background: theme.palette.text.disabled,
    color: theme.palette.text.disabled,
    borderRadius: 3,
  },
}));

export default useStyles;
