import React, { useState, useEffect } from 'react';
import useStyles from './styles';
import { Typography, TextField } from '@material-ui/core';
import rightArrow from 'assets/icons/arrowRight.svg';
import userProfileStore from 'store/userProfileStore';

const Content = ({ title, value = null, name }) => {
  const classes = useStyles();
  const { doUpdateUserProfile, updateUserStore } = userProfileStore();
  const [isTextFieldDisabled, setTextFieldDisable] = useState(true);
  const [updatedValue, setUpdateValue] = useState(value);
  const [validationError, setValidationError] = useState(null);

  const handleTextFieldChange = ({ target: { value } }) => {
    setUpdateValue(value);
    if (name === 'phone') {
      if (value.length > 10 || value.length < 10) {
        setValidationError('Enter your phone number using the following format: 0000000000');
      } else {
        setValidationError('');
      }
    }
    if (name === 'email') {
      const emailRegex = new RegExp('^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$');
      if (!emailRegex.test(value)) {
        setValidationError('Enter your email address using the following format: sample@mail.com');
      } else {
        setValidationError('');
      }
    }
  };

  const handleTextFieldClick = (e) => {
    if (isTextFieldDisabled) {
      setTextFieldDisable(false);
      setUpdateValue(value);
    }
  };

  const handleUpdateProfile = async () => {
    setTextFieldDisable(true);
    if (validationError || !updatedValue) {
      setValidationError(null);
      setUpdateValue(value);
      return;
    }
    if (updatedValue === value) return;
    let updatedDetails = {};
    if (name === 'firstName') {
      const updatedName = updatedValue.split(' ');
      if (updatedName.length === 1) {
        updatedDetails.firstName = updatedName[0];
        updatedDetails.lastName = '';
      } else {
        updatedDetails.firstName = [...updatedName.slice(0, updatedName.length - 1)].join(' ');
        updatedDetails.lastName = updatedName[updatedName.length - 1];
      }
    } else {
      updatedDetails = {
        [name]: updatedValue,
      };
    }
    const resp = await doUpdateUserProfile(updatedDetails);
    if (resp) {
      updateUserStore(updatedDetails);
    }
  };

  const getType = () => {
    switch (name) {
      case 'password':
        return 'password';
      case 'phone':
        return 'number';
      case 'email':
        return 'email';
      case 'dateOfBirth':
        return 'date';
      default:
        return 'text';
    }
  };

  useEffect(() => {
    setUpdateValue(value);
  }, [value]);

  return (
    <div className={classes.wrapper}>
      <Typography variant="h6">{title}</Typography>
      {value ? (
        <div className={classes.description}>
          <TextField
            value={updatedValue}
            type={getType()}
            disabled={isTextFieldDisabled}
            onClick={handleTextFieldClick}
            onChange={handleTextFieldChange}
            onBlur={handleUpdateProfile}
            helperText={validationError}
            FormHelperTextProps={{
              className: classes.helperText,
            }}
            classes={{
              root: classes.textField,
            }}
          />
        </div>
      ) : (
        <div className={classes.rightArrow}>
          <img src={rightArrow} alt="Right Arrow" />
        </div>
      )}
    </div>
  );
};

export default Content;
