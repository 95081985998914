import React, { useState, useEffect } from 'react';
import { TextField, Button } from '@material-ui/core';
import cs from 'classnames';
import { useHistory } from 'react-router-dom';

import journalIntro from 'assets/icons/journalIntro.png';
import useSignUpStore from 'store/signupStore';
import Alert from 'components/Alert';

import useStyles from './styles';

const LandingComponent = ({ updateStep }) => {
  const classes = useStyles();
  const history = useHistory();
  const { doOtpValidation, error, resetState } = useSignUpStore();
  const [otpField, setOtpField] = useState('');
  const [loading, setLoading] = useState(false);

  const handleOtpField = (event) => {
    const {
      target: { value },
    } = event;

    setOtpField(value);
  };

  const handleOtpCodeSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const resp = await doOtpValidation(otpField);
      if (resp) {
        resetState();
        updateStep();
      }
      setLoading(false);
    } catch ({ response }) {
      setLoading(false);
    }
  };

  const redirectToLogin = () => history.push('/onboarding/login');

  useEffect(() => {
    resetState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <form onSubmit={handleOtpCodeSubmit}>
      <div className={classes.wrapper}>
        <img src={journalIntro} className={classes.introImg} alt="Intro" />
        <div className={classes.title}>Enter the code from your parent’s email to get started.</div>
        <div className={classes.resendCode}>Resend code</div>
        <TextField
          name="otpCode"
          type="text"
          value={otpField}
          placeholder="Enter the OTP Code"
          variant="outlined"
          color="primary"
          inputProps={{ 'data-testid': 'code-field' }}
          onChange={handleOtpField}
          classes={{
            root: classes.otpField,
          }}
        />
        {error && <Alert severity="error" message={error} />}
        <Button
          type="submit"
          color="primary"
          variant="contained"
          className={classes.setupButton}
          disabled={!otpField || loading}
        >
          Setup Account
        </Button>
        <div
          className={cs(classes.haveAccount, {
            [classes.isError]: error,
          })}
        >
          Already have an account?{' '}
          <span className={classes.resendCode} onClick={redirectToLogin}>
            Login here.
          </span>
        </div>
      </div>
    </form>
  );
};

export default LandingComponent;
