import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  dateWrapper: {
    padding: '60px 0 0 90px',
    [theme.breakpoints.down('sm')]: {
      padding: '60px 0 0',
      width: '100%',
    },
    '&.MuiPickersDay-daySelected': {
      background: 'red',
    },
  },
  dateInput: {
    display: 'flex',
    maxWidth: 476,
    border: `1px solid ${theme.palette.primary.dark}`,
    borderRadius: 10,
    padding: '20px 32px',
    position: 'relative',
    '& .MuiInput-underline:before': {
      borderBottom: 0,
    },
    '& .MuiInput-underline:after': {
      borderBottom: 0,
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottom: 0,
    },
    '& .MuiFormControl-marginNormal': {
      marginTop: 0,
      marginBottom: 0,
    },
  },
  datePicker: {
    position: 'relative',
  },
}));

export default useStyles;
