import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  contentWrapper: {
    margin: '-24px 0',
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
    },
    [theme.breakpoints.down('xs')]: {
      margin: 0,
    },
  },
  rightItem: {
    height: '100vh',
    overflowY: 'auto',
    borderLeft: `1px solid #DDEAE0`,
    padding: theme.spacing(8, 0),
    [theme.breakpoints.down('sm')]: {
      borderLeft: 0,
      position: 'absolute',
      width: '100%',
      background: theme.palette.common.white,
      display: 'none',
      overflowY: 'initial',
    },
  },
  showRightItem: {
    display: 'block',
  },
  leftItem: {
    height: '100vh',
    overflowY: 'auto',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  wrapper: {
    maxWidth: 650,
    color: theme.palette.text.primary,
    display: 'flex',
    padding: theme.spacing(8),
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(8, 0),
      margin: '0 auto',
    },
    [theme.breakpoints.down('xs')]: {
      margin: 0,
    },
  },
  searchWrapper: {
    display: 'flex',
    marginTop: 35,
    width: '100%',
  },
  search: {
    display: 'flex',
    position: 'relative',
    borderRadius: 15,
    width: '100%',
    background: '#F6F6F6',
    marginRight: 24,
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  searchRoot: {
    color: 'inherit',
    width: '100%',
  },
  searchInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    fontFamily: 'Montserrat',
    fontSize: 14,
    color: theme.palette.text.secondary,
  },
  filterIcon: {
    background: '#F6F6F6',
    borderRadius: 15,
    width: 50,
    height: 50,
  },
  pendingForms: {
    border: `1px solid ${theme.palette.primary.dark}`,
    boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.15)',
    borderRadius: 5,
    width: 'inherit',
    display: 'flex',
    alignItems: 'center',
    marginTop: 10,
    padding: '8px 16px',
    cursor: 'pointer',
  },
  pendingText: {
    fontFamily: 'Montserrat',
    fontSize: 15,
    color: theme.palette.text.primary,
    marginLeft: 16,
  },
  completedSurvey: {
    marginTop: 20,
  },
  gridItems: {
    paddingBottom: 10,
  },
  selectSurvey: {
    fontFamily: 'Montserrat',
    fontSize: 18,
    letterSpacing: 1,
    color: '#494949',
    textAlign: 'center',
  },
  menu: {
    boxShadow: '0px 4px 13px #DADADA',
    borderRadius: 10,
  },
  menuItems: {
    borderBottom: '1px solid #F2F2F5',
    fontFamily: 'Montserrat',
    fontSize: 16,
    color: theme.palette.text.primary,
    '&:last-child': {
      borderBottom: 0,
    },
  },
  menuItemSelected: {
    backgroundColor: `${theme.palette.common.white} !important`,
    color: theme.palette.primary.main,
  },
  navBar: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      marginLeft: 4,
    },
  },
}));

export default useStyles;
