import React, { useEffect, useState } from 'react';
import { RadioGroup, Radio, FormControlLabel } from '@material-ui/core';
import cs from 'classnames';

import NavBar from 'components/NavBar';
import ProgressButton from 'components/ProgressButton';
import Alert from 'components/Alert';
import useJournalStore from 'store/journalStore';
import { getQuestionRequired } from 'helper/helper';

import useStyles from './styles';

const RatingQuestion = ({ title, uid, options, isRequired }) => {
  const classes = useStyles();
  const { updateAnswerByUID, error, surveyAnswers } = useJournalStore();
  const [loading, setLoading] = useState(false);
  const [ratingValue, setRatingValue] = useState('');
  const [leastValue, setLeastValue] = useState('');
  const [highestValue, setHighestValue] = useState('');

  const handleRadioChange = ({ target: { value } }) => setRatingValue(value);

  const handleAnswer = async () => {
    if (!isRequired && !ratingValue) return true;
    setLoading(true);
    const selectedOption = options.filter((option) => option.key === ratingValue);
    const answerFormData = new FormData();
    answerFormData.append('Uid', uid);
    answerFormData.append('AnswerText', JSON.stringify(selectedOption));
    const resp = await updateAnswerByUID(answerFormData);
    setRatingValue('');
    setLoading(false);
    return resp;
  };

  useEffect(() => {
    setLeastValue(options[0].value);
    setHighestValue(options[options.length - 1].value);
  }, [uid, options]);

  useEffect(() => {
    setRatingValue('');
    const availableAnswer = surveyAnswers?.find((answer) => answer.uid === uid);
    if (availableAnswer?.answer) {
      const ans = JSON.parse(availableAnswer?.answer);
      setRatingValue(ans[0].key);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uid]);

  return (
    <>
      <NavBar title={title} />
      <div className={classes.ratingWrapper}>
        <div className={classes.ratingText}>{leastValue}</div>
        <RadioGroup
          name="radio-question"
          value={ratingValue}
          onChange={handleRadioChange}
          classes={{
            root: classes.radioGroupRoot,
          }}
        >
          {options.map((rating) => (
            <FormControlLabel
              key={rating.key}
              control={
                <Radio
                  classes={{
                    root: classes.root,
                  }}
                  checkedIcon={<span className={cs(classes.icon, classes.checkedIcon)} />}
                />
              }
              label={rating.key}
              labelPlacement={'start'}
              value={rating.key}
              classes={{
                root: classes.formControlLabelRoot,
              }}
            />
          ))}
        </RadioGroup>
        <div className={classes.ratingText}>{highestValue}</div>
      </div>
      {error && <Alert severity="error" message={error} />}
      <ProgressButton
        nextButtonDisabled={getQuestionRequired(isRequired, !ratingValue)}
        nextButtonClicked={handleAnswer}
        loading={loading}
      />
    </>
  );
};

export default RatingQuestion;
