import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import useJournalStore from 'store/journalStore';
import useStyles from './styles';

const ProgressBar = () => {
  const classes = useStyles();
  const { surveyQuestions } = useJournalStore();
  const { questionId } = useParams();
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (surveyQuestions.length) {
      const index = surveyQuestions.findIndex((question) => question.uid === questionId);
      setCurrentIndex(index + 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionId]);

  return (
    <div className={classes.wrapper}>
      <div className={classes.progressWrapper}>{`${currentIndex} / ${surveyQuestions.length}`}</div>
    </div>
  );
};

export default ProgressBar;
