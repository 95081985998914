import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  surveyWrapper: {
    padding: '0 38px',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      padding: '0 0 24px',
    },
  },
  title: {
    fontFamily: 'Montserrat',
    fontSize: 20,
    letterSpacing: 1,
    color: theme.palette.text.primary,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  contentWrapper: {
    marginTop: 40,
  },
  surveyContent: {
    padding: '15px 20px',
    border: '2px solid #DDEAE0',
    borderRadius: 10,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginBottom: 15,
    '&:last-child': {
      marginBottom: 0,
    },
  },
  questionText: {
    fontFamily: 'Montserrat',
    fontSize: 16,
    textAlign: 'left',
    color: theme.palette.text.primary,
  },
  answerWrapper: {
    marginTop: 5,
    width: '100%',
  },
}));

export default useStyles;
