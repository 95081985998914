import React, { useState, useEffect } from 'react';
import { Typography, IconButton, InputBase, Grid, Menu, MenuItem } from '@material-ui/core';
import cs from 'classnames';
import dayjs from 'dayjs';
import { useHistory } from 'react-router-dom';

import filterIcon from 'assets/icons/filter.svg';
import searchIcon from 'assets/icons/search.svg';
import warning from 'assets/icons/warning.svg';
import EntryCard from 'components/EntryCard';
import FeedViewer from 'components/FeedViewer';
import NavBar from 'components/NavBar';
import useJournalStore from 'store/journalStore';
import { menuItems } from 'constants/menu';
import CircularProgress from 'components/CircularProgress';

import useStyles from './styles';

const Feed = () => {
  const classes = useStyles();
  const history = useHistory();
  const { doGetFilteredSurvey, doGetSurveyAnswers, surveyAnswers, surveyLength, completedSurvey, loadingAnswers } =
    useJournalStore();
  const [selectedSurvey, setSelectedSurvey] = useState(null);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [searchedRecords, setSearchRecords] = useState([]);
  const [menuEl, setMenuEl] = useState(null);
  const [selectedMenuItem, setSelectedMenuItem] = useState({});
  const [sortedSurvey, setSortedSurvey] = useState([]);
  const [filterSurvey, setFilteredSurvey] = useState(completedSurvey);
  const [loadingSurveyAnswers, setLoadingSurveyAnswers] = useState(false);
  const [filteredAnswers, setFilteredAnswers] = useState();

  const handleSelectedSurvey = (survey) => async () => {
    setLoadingSurveyAnswers(true);
    setSelectedSurvey(survey);
    await doGetSurveyAnswers(survey.surveyResponseId);
    setLoadingSurveyAnswers(false);
  };

  const handleMenuOpen = ({ currentTarget }) => {
    setMenuEl(currentTarget);
  };

  const handleMenuSelect = (item) => () => {
    if (item.id === selectedMenuItem.id) {
      setSelectedMenuItem({});
      setSortedSurvey([]);
    } else {
      setSelectedMenuItem(item);
      if (item.id === 1) {
        let sorted = [...completedSurvey];
        sorted = sorted.sort((a, b) => {
          return new Date(b.dueDate) - new Date(a.dueDate);
        });
        setSortedSurvey(sorted);
      }
      if (item.id === 2) {
        let sorted = [...completedSurvey];
        sorted = sorted.sort((a, b) => {
          return new Date(a.dueDate) - new Date(b.dueDate);
        });
        setSortedSurvey(sorted);
      }
    }
    handleMenuClose();
  };

  const handleMenuClose = () => {
    setMenuEl(null);
  };

  const handleSurveyClick = () => {
    setSelectedSurvey(null);
  };

  const handleSearchChange = ({ target: { value } }) => {
    setSearch(value);
    const filterValue = filterSurvey.filter((data) => data.name.toLowerCase().includes(value.toLowerCase()));
    setSearchRecords(filterValue);
  };

  const handlePendingSurveyRedirect = () => {
    history.push('/');
  };

  useEffect(() => {
    const fetchSurveys = async () => {
      setLoading(true);
      await doGetFilteredSurvey(3);
      await doGetFilteredSurvey('1,2');
      setLoading(false);
    };
    fetchSurveys();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (sortedSurvey.length) {
      setFilteredSurvey(sortedSurvey);
    } else {
      setFilteredSurvey(completedSurvey);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMenuItem, loading]);

  useEffect(() => {
    if (surveyAnswers.length) {
      const filteredSurveyAnswer = surveyAnswers.filter((ans) => ans.answer || ans.description);
      setFilteredAnswers(filteredSurveyAnswer);
    }
  }, [surveyAnswers]);

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <div className={classes.contentWrapper}>
      <Grid container>
        <Grid item sm={12} md={6} className={classes.leftItem}>
          <div className={classes.wrapper}>
            <Typography variant="subtitle1">My Journal Feed</Typography>
            <>
              <div className={classes.searchWrapper}>
                <div className={classes.search}>
                  <div className={classes.searchIcon}>
                    <img src={searchIcon} alt="Search Icon" />
                  </div>
                  <InputBase
                    placeholder="Search…"
                    onChange={handleSearchChange}
                    value={search}
                    classes={{
                      root: classes.searchRoot,
                      input: classes.searchInput,
                    }}
                  />
                </div>
                <IconButton className={classes.filterIcon} onClick={handleMenuOpen}>
                  <img src={filterIcon} alt="Filter Icon" />
                </IconButton>
              </div>
              {surveyLength > 0 && (
                <div className={classes.pendingForms} onClick={handlePendingSurveyRedirect}>
                  <img src={warning} alt="Warning" />
                  <div className={classes.pendingText}>You have {surveyLength} pending forms</div>
                </div>
              )}
              <Grid container className={classes.completedSurvey}>
                {search
                  ? searchedRecords.map((survey) => (
                      <Grid key={survey.surveyResponseId} item xs={12} className={classes.gridItems}>
                        <EntryCard
                          id={survey.surveyResponseId}
                          title={survey.activityName}
                          dueDate={survey.dueDate}
                          numberOfQuestions={survey.totalQuestionsCount}
                          surveyName={survey.programName}
                          description={survey.description}
                        />
                      </Grid>
                    ))
                  : filterSurvey.map((survey) => (
                      <Grid key={survey.surveyResponseId} item xs={12} className={classes.gridItems}>
                        <EntryCard
                          id={survey.surveyResponseId}
                          title={survey.activityName}
                          dueDate={survey.dueDate}
                          numberOfQuestions={survey.totalQuestionsCount}
                          surveyName={survey.programName}
                          handleClick={handleSelectedSurvey(survey)}
                          description={survey.description}
                        />
                      </Grid>
                    ))}
              </Grid>
            </>
          </div>
        </Grid>
        <Grid
          item
          sm={12}
          md={6}
          className={cs(classes.rightItem, {
            [classes.showRightItem]: selectedSurvey,
          })}
        >
          {selectedSurvey ? (
            loadingAnswers || loadingSurveyAnswers ? (
              <CircularProgress />
            ) : (
              <>
                <div className={classes.navBar}>
                  <NavBar
                    title={`${selectedSurvey.activityName} ${
                      selectedSurvey.dueDate ? `| ${dayjs(selectedSurvey.dueDate).format('MMMM DD, YYYY')}` : ''
                    }`}
                    isFeed={true}
                    handleSurveyClick={handleSurveyClick}
                  />
                </div>
                <FeedViewer
                  surveyAnswers={filteredAnswers}
                  title={selectedSurvey.activityName}
                  date={selectedSurvey.dueDate}
                />
              </>
            )
          ) : (
            <div className={classes.selectSurvey}>Select a survey on the left to view.</div>
          )}
        </Grid>
      </Grid>
      <Menu
        id="filter-menu"
        anchorEl={menuEl}
        keepMounted
        open={Boolean(menuEl)}
        onClose={handleMenuClose}
        classes={{
          paper: classes.menu,
        }}
      >
        {menuItems.map((item) => (
          <MenuItem
            key={item.id}
            onClick={handleMenuSelect(item)}
            classes={{
              root: classes.menuItems,
              selected: classes.menuItemSelected,
            }}
            selected={selectedMenuItem.id === item.id}
          >
            {item.title}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default Feed;
