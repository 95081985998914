import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.text.primary,
  },
  textBlock: {
    width: 'auto',
  },
  leftArrowIcon: {
    boxShadow: '0px 1px 4px grey',
    borderRadius: 15,
    marginRight: 40,
    [theme.breakpoints.down('xs')]: {
      marginRight: 20,
    },
  },
  titleWrapper: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 700,
    [theme.breakpoints.down('xs')]: {
      '& h6': {
        fontSize: 16,
      },
    },
  },
  description: {
    fontFamily: 'Montserrat',
    fontWeight: 300,
    fontSize: 18,
    color: theme.palette.text.primary,
    marginTop: 10,
  },
}));

export default useStyles;
