import { makeStyles } from '@material-ui/core';
import env from 'env';

const useStyles = makeStyles((theme) => ({
  loading: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      marginTop: env.isMobile ? 70 : 60,
    },
  },
}));

export default useStyles;
