import React, { useEffect, useState } from 'react';
import { emojify } from 'react-emojione';

import useStyles from './styles';

const MoodAnswer = ({ currentMood }) => {
  const classes = useStyles();
  const [mood, setMood] = useState();

  const emojiConfig = {
    convertShortnames: true,
    convertUnicode: true,
    convertAscii: true,
    style: {
      height: 60,
      margin: 10,
    },
  };

  useEffect(() => {
    try {
      const answer = JSON.parse(currentMood);
      setMood(answer[0]);
    } catch (error) {
      console.log(error);
    }
  }, [currentMood]);

  return (
    <div className={classes.emojiWrapper}>
      <div>{mood && emojify(mood.key, emojiConfig)}</div>
      <div className={classes.moodText}>{mood?.value}</div>
    </div>
  );
};

export default MoodAnswer;
