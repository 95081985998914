import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  radioButtonWrapper: {
    borderRadius: 12,
    border: `2px solid ${theme.palette.text.disabled}`,
    maxWidth: 650,
    width: '100%',
    marginBottom: 15,
  },
  formControlLabelRoot: {
    margin: '10px 18px',
    alignItems: 'center',
    '& .MuiTypography-root': {
      marginLeft: 18,
    },
  },
  root: {
    padding: 0,
    background: theme.palette.text.disabled,
    color: theme.palette.text.disabled,
    borderRadius: '50%',
  },
  icon: {
    borderRadius: '50%',
    width: 24,
    height: 24,
  },
  checkedIcon: {
    backgroundColor: theme.palette.primary.dark,
    '&:before': {
      display: 'block',
      width: 24,
      height: 24,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""',
    },
  },
}));

export default useStyles;
