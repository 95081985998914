import React, { useEffect, useState } from 'react';
import { Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import cs from 'classnames';

import EntryCard from 'components/EntryCard';
import useJournalStore from 'store/journalStore';
import CircularProgress from 'components/CircularProgress';

import useStyles from './styles';
import AllCaughtUp from 'components/AllCaughtUp';

const InProgress = () => {
  const classes = useStyles();
  const history = useHistory();
  const { surveys, doGetFilteredSurvey } = useJournalStore();
  const [loading, setLoading] = useState(false);

  const redirectToSurvey = (surveyResponseId) => () => {
    history.push(`/survey/${surveyResponseId}`);
  };

  useEffect(() => {
    const fetchSurveys = async () => {
      setLoading(true);
      await doGetFilteredSurvey(2);
      setLoading(false);
    };
    fetchSurveys();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <div className={cs(classes.wrapper, { [classes.loading]: loading })}>
      <Typography variant="subtitle1" className={classes.title}>
        Today
      </Typography>
      {surveys.length > 0 ? (
        <div className={classes.entryCardWrapper}>
          {surveys.map((survey) => (
            <EntryCard
              key={survey.surveyResponseId}
              id={survey.surveyResponseId}
              title={survey.activityName}
              dueDate={survey.dueDate}
              numberOfQuestions={survey.totalQuestionsCount}
              surveyName={survey.programName}
              handleClick={redirectToSurvey(survey.surveyResponseId)}
              description={survey.description}
            />
          ))}
        </div>
      ) : (
        <AllCaughtUp />
      )}
    </div>
  );
};

export default InProgress;
