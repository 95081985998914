import create from 'zustand';

import axios, { getValueFromStorage, setToken } from 'http/request';

const useSignUpStore = create((set, get) => ({
  userDetails: {
    email: '',
    phone: '',
  },
  user: {},
  id: null,
  userProfilePhoto: null,
  webUserId: null,
  error: null,
  authenticated: null,
  surveyResponseId: null,
  loadingData: true,
  userSignUpData: {},
  setLocalStorageValues: async () => {
    const userId = await getValueFromStorage('userId');
    const jwtToken = await getValueFromStorage('jwtToken');
    set({
      userId,
      jwtToken,
      authenticated: !!jwtToken,
      loadingData: false,
    });
  },
  doOtpValidation: async (code) => {
    try {
      const response = await axios.post(`/code/verify-code?code=${code}`);
      set({
        surveyResponseId: response?.data.surveyResponseId,
        id: response?.data.id,
      });
      return true;
    } catch (err) {
      const { text = '' } = err.response?.data[0];
      set({
        error: text,
      });
      return false;
    }
  },
  doRegistration: async (userInfo) => {
    set({ userDetails: userInfo });
    const { doLogin } = get();
    try {
      await axios.post(`/user/signUp`, userInfo);
      const { phone, ...rest } = userInfo;
      const loginDetails = {
        emailOrUsernameOrPhone: rest.email,
        password: rest.password,
      };
      const resp = await doLogin(loginDetails);
      if (resp) {
        return true;
      } else {
        return false;
      }
    } catch ({ response }) {
      set({
        error: response?.data?.title ? response?.data?.title : response?.data[0].text,
      });
      return false;
    }
  },
  doUploadImage: async (fileName, image) => {
    set({ userProfilePhoto: image });
    const { userId } = get();
    try {
      await axios.put(`/user/update-profile-picture`, {
        id: userId,
        fileName,
        base64ImageString: image.split(',').pop(),
      });
      return true;
    } catch ({ response }) {
      set({
        error: response?.data?.message ? response?.data?.message : response?.data[0].text,
      });
      return false;
    }
  },
  doLogin: async (credentials) => {
    try {
      const resp = await axios.post(`/auth/authenticate`, credentials);
      const { data } = resp;
      await setToken('jwtToken', data.jwtToken);
      // Capacitor doesn't store Integer in storage so need to convert into string
      await setToken('userId', data.id.toString());
      await setToken('refreshToken', data.refreshToken);
      set(() => ({ user: data, userId: data.id, refreshToken: data.refreshToken }));
      return data.jwtToken;
    } catch ({ response }) {
      set({ error: response?.data?.title ? response?.data?.title : response?.data });
      return false;
    }
  },
  doFetchUserSignUpDetails: async (code) => {
    try {
      const resp = await axios.get(`/Registrant/GetByRegistrantInviteCodeId?registrantInviteCodeId=${code}`);
      const { data } = resp;
      set({ userSignUpData: data });
      return data;
    } catch (e) {
      const {
        response: { data },
      } = e;
      return false;
    }
  },
  setAuthentication: (token) => set({ authenticated: token }),
  resetState: () => set({ error: null }),
}));

export default useSignUpStore;
