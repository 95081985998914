import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: 480,
    marginTop: 30,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    height: '100%',
    flexGrow: 1,
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
      width: 'auto',
    },
  },
  imageWrapper: {
    position: 'relative',
  },
  userImage: {
    width: 'auto',
    height: 'auto',
    maxWidth: 720,
    maxHeight: 300,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  captureButton: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    textAlign: 'center',
  },
  orText: {
    fontFamily: 'Montserrat',
    fontSize: 10,
    color: theme.palette.text.hint,
    textTransform: 'uppercase',
    margin: '20px 0 15px',
  },
  uploadButton: {
    boxShadow: '0px 4px 13px #bababa',
    borderRadius: 10,
    fontFamily: 'Montserrat',
    fontWeight: 300,
    fontSize: 12,
    color: '#00AE84',
    width: 260,
    marginTop: 20,
    [theme.breakpoints.down('xs')]: {
      width: 'auto',
      minWidth: 200,
    },
  },
  retakeButton: {
    boxShadow: '0px 4px 13px #bababa',
    borderRadius: 10,
    fontFamily: 'Montserrat',
    fontWeight: 300,
    fontSize: 12,
    color: '#00AE84',
    width: 178,
    marginTop: 30,
  },
  inputFile: {
    display: 'none',
  },
  uploadPhotoDialog: {
    borderRadius: 15,
  },
  uploadPhotoModal: {
    padding: '28px 28px 50px 28px',
    display: 'flex',
    flexDirection: 'column',
    width: 315,
    alignItems: 'center',
    borderRadius: 15,
    [theme.breakpoints.down('xs')]: {
      width: 'auto',
    },
  },
  cropperWrapper: {
    position: 'relative',
  },
  minusButon: {
    position: 'absolute',
    bottom: '10%',
    left: '7%',
  },
  addButton: {
    position: 'absolute',
    bottom: '9%',
    right: '5%',
  },
  takePhoto: {
    background: theme.palette.success.main,
    color: '#FFFFFF',
    height: 50,
    borderRadius: 10,
  },
  nextButton: {
    background: theme.palette.primary.dark,
    borderRadius: 10,
    color: 'white',
    marginTop: 40,
    width: 260,
    [theme.breakpoints.down('xs')]: {
      width: 'auto',
      minWidth: 200,
    },
  },
  skipNow: {
    fontFamily: 'Montserrat',
    fontSize: 14,
    textDecorationLine: 'underline',
    color: theme.palette.text.hint,
    marginTop: 15,
    cursor: 'pointer',
  },
  isError: {
    marginTop: 20,
  },
  buttonWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    bottom: 0,
    position: 'absolute',
  },
}));

export default useStyles;
