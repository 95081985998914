import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  allDone: {
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
    },
  },
  caughtUp: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  caughtUpImg: {
    width: 550,
    height: 400,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      height: 'auto',
    },
  },
}));

export default useStyles;
