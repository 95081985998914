import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  audioWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  audioInput: {
    display: 'none',
  },
  audioWaveform: {
    width: 180,
    marginLeft: 20,
  },
  audioControlButton: {
    cursor: 'pointer',
  },
}));

export default useStyles;
