import React, { useEffect, useState } from 'react';
import { Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import AllCaughtUp from 'components/AllCaughtUp';
import EntryCard from 'components/EntryCard';
import Alert from 'components/Alert';
import useJournalStore from 'store/journalStore';
import CircularProgress from 'components/CircularProgress';

import useStyles from './styles';

const Dashboard = () => {
  const classes = useStyles();
  const history = useHistory();
  const { surveys, doGetFilteredSurvey, updateSurveyStatus, error } = useJournalStore();
  const [loading, setLoading] = useState(false);

  const handleSurveyClick =
    ({ surveyResponseId }) =>
    () => {
      history.push(`/survey/${surveyResponseId}`);
    };

  useEffect(() => {
    const fetchSurveys = async () => {
      setLoading(true);
      await doGetFilteredSurvey('1,2');
      setLoading(false);
    };
    fetchSurveys();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <div className={classes.wrapper}>
      <Typography variant="subtitle1">Today</Typography>
      {surveys.length > 0 ? (
        <div className={classes.entryCardWrapper}>
          {surveys.map((survey) => (
            <EntryCard
              key={survey.surveyResponseId}
              id={survey.surveyResponseId}
              title={survey.activityName}
              dueDate={survey.dueDate}
              numberOfQuestions={survey.totalQuestionsCount}
              surveyName={survey.programName}
              handleClick={handleSurveyClick(survey)}
              description={survey.description}
            />
          ))}
        </div>
      ) : (
        <AllCaughtUp />
      )}
      {error && <Alert severity="error" message={error} />}
    </div>
  );
};

export default Dashboard;
