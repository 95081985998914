import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  emojiWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 'fit-content',
  },
  moodText: {
    color: theme.palette.text.primary,
    fontSize: 16,
    fontFamily: 'Montserrat',
    textAlign: 'left',
  },
}));

export default useStyles;
