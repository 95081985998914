import React, { useEffect, useState } from 'react';
import { AppBar, Drawer, Hidden, IconButton, List, Toolbar, useTheme, Button } from '@material-ui/core';
import { NavLink, useHistory, useParams } from 'react-router-dom';
import get from 'lodash/get';

import ExitSurveyModal from 'components/ExitSurveyModal';
import { sidebarContent } from 'constants/sidebar';
import hamburgerMenu from 'assets/icons/hamburger_menu.svg';
import userAvatar from 'assets/icons/userImage.svg';
import userProfileStore from 'store/userProfileStore';
import { logoutUser } from 'helper/helper';

import useStyles from './styles';

const Sidebar = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const { questionId = '' } = useParams();
  const { userData, doGetUserById } = userProfileStore();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isExitSurveyModal, setExitSurveyModal] = useState(false);
  const [currentRedirectingPath, setCurrentRedirectingPath] = useState('');
  const firstName = get(userData, 'firstName', '');
  const lastName = get(userData, 'lastName', '');
  const profileUrl = get(userData, 'profileUrl', '');

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleUserProfile = (e) => {
    if (questionId) {
      e.preventDefault();
      setCurrentRedirectingPath('/user-profile');
      setExitSurveyModal(true);
      return;
    }
    history.push('/user-profile');
  };

  const handleMobileClose = (path) => (e) => {
    if (questionId) {
      e.preventDefault();
      setCurrentRedirectingPath(path);
      setExitSurveyModal(true);
    }
    if (mobileOpen) {
      handleDrawerToggle();
    }
  };

  const handleExitSurveyModal = () => setExitSurveyModal(!isExitSurveyModal);

  const handleLogout = async (e) => {
    if (questionId) {
      e.preventDefault();
      setCurrentRedirectingPath('logout');
      setExitSurveyModal(true);
      if (mobileOpen) {
        handleDrawerToggle();
      }
      return;
    }
    logoutUser();
  };

  useEffect(() => {
    doGetUserById();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const drawer = (
    <div className={classes.drawerContent}>
      <List className={classes.list}>
        {sidebarContent.map((content, index) => (
          <NavLink
            to={content.path}
            exact={content.path === '/'}
            className={classes.sidebarLinks}
            activeClassName={classes.activeLink}
            onClick={handleMobileClose(content.path)}
            key={index}
          >
            <img src={content.icon} alt="Navbar Icon" />
            <div className={classes.listItemText}>{content.title}</div>
          </NavLink>
        ))}
      </List>
      <div>
        <div className={classes.userProfile} onClick={handleUserProfile}>
          {profileUrl && <img src={profileUrl} className={classes.userImage} alt="User Profile" />}
          {firstName && <div className={classes.userName}>{`${firstName} ${lastName}`}</div>}
        </div>
        <Button fullWidth className={classes.logoutButton} onClick={handleLogout}>
          Logout
        </Button>
      </div>
    </div>
  );

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar className={classes.toolBar}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <img src={hamburgerMenu} alt="Menu" className={classes.hamburderMenu} />
          </IconButton>
          <div onClick={handleUserProfile}>
            <img src={profileUrl ? profileUrl : userAvatar} className={classes.userImageMobile} alt="User Profile" />
          </div>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        <Hidden smUp implementation="css">
          <Drawer
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true,
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>{props.children}</main>
      {isExitSurveyModal && (
        <ExitSurveyModal
          isOpen={isExitSurveyModal}
          onClose={handleExitSurveyModal}
          currentPath={currentRedirectingPath}
        />
      )}
    </div>
  );
};

export default Sidebar;
