import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {
      marginTop: 50,
    },
  },
  questionWrapper: {
    display: 'flex',
    flexDirection: 'column',
    margin: '24px 95px 0',

    [theme.breakpoints.down('sm')]: {
      margin: '24px 0 0',
      alignItems: 'center',
    },
  },
}));

export default useStyles;
