import React, { useEffect, useState } from 'react';
import { IconButton } from '@material-ui/core';
import cs from 'classnames';
import { emojify } from 'react-emojione';

import NavBar from 'components/NavBar';
import ProgressButton from 'components/ProgressButton';
import Alert from 'components/Alert';
import useJournalStore from 'store/journalStore';
import { getQuestionRequired } from 'helper/helper';

import useStyles from './styles';

const MoodQuestion = ({ title, uid, options, isRequired }) => {
  const classes = useStyles();
  const { updateAnswerByUID, error, surveyAnswers } = useJournalStore();
  const [currentMood, setCurrentMood] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleMoodChange = (mood) => () => {
    setCurrentMood(mood);
  };

  const getClassName = (moodName) => {
    return moodName === currentMood?.value ? cs(classes.moodButton, classes.moodIconButton) : classes.moodIconButton;
  };

  const handleAnswer = async () => {
    if (!isRequired && !currentMood) return true;
    setLoading(true);
    const answerFormData = new FormData();
    answerFormData.append('Uid', uid);
    answerFormData.append('AnswerText', JSON.stringify([currentMood]));
    const resp = await updateAnswerByUID(answerFormData);
    setLoading(false);
    setCurrentMood('');
    return resp;
  };

  const emojiConfig = {
    convertShortnames: true,
    convertUnicode: true,
    convertAscii: true,
    style: {
      height: 60,
      margin: 10,
    },
  };

  useEffect(() => {
    setCurrentMood(null);
    const availableAnswer = surveyAnswers?.find((answer) => answer.uid === uid);
    if (availableAnswer?.answer) {
      const ans = JSON.parse(availableAnswer?.answer);
      setCurrentMood(ans[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uid]);

  return (
    <>
      <NavBar title={title} />
      <div className={classes.moodWrapper}>
        <div className={classes.iconsWrapper}>
          {options.map((mood) => (
            <IconButton key={mood.key} onClick={handleMoodChange(mood)} className={getClassName(mood.value)}>
              {emojify(mood.key, emojiConfig)}
            </IconButton>
          ))}
        </div>
        {currentMood && <div className={classes.moodName}>{currentMood.value}</div>}
      </div>
      {error && <Alert severity="error" message={error} />}
      <ProgressButton
        nextButtonDisabled={getQuestionRequired(isRequired, !currentMood)}
        nextButtonClicked={handleAnswer}
        loading={loading}
      />
    </>
  );
};

export default MoodQuestion;
