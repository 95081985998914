import React, { useEffect, useRef, useState } from 'react';
import WaveSurfer from 'wavesurfer.js';

import playButtonDark from 'assets/icons/playButtonDark.png';
import pauseButtonDark from 'assets/icons/pauseDark.svg';

import useStyles from './styles';

const AudioAnswer = ({ audio }) => {
  const classes = useStyles();
  const waveformRef = useRef();
  const [isPlaying, setPlaying] = useState(false);
  const playRecorderAudio = () => {
    waveformRef?.current.play();
    setPlaying(true);
    waveformRef?.current?.on('finish', () => setPlaying(false));
  };

  const pauseRecorderAudio = () => {
    waveformRef?.current?.pause();
    setPlaying(false);
  };

  useEffect(() => {
    if (audio) {
      waveformRef.current = WaveSurfer.create({
        container: '#waveform',
        waveColor: 'black',
        progressColor: '#FFAD49',
        barHeight: 8,
        height: 50,
        barWidth: 2,
      });
      waveformRef?.current.load(audio);
    }

    return () => {
      if (audio) {
        waveformRef?.current?.destroy();
      }
    };
  }, [audio]);

  return (
    <div className={classes.audioWrapper}>
      {isPlaying ? (
        <img src={pauseButtonDark} alt="Pause" onClick={pauseRecorderAudio} className={classes.audioControlButton} />
      ) : (
        <img src={playButtonDark} alt="Play" onClick={playRecorderAudio} className={classes.audioControlButton} />
      )}

      <div id="waveform" className={classes.audioWaveform} ref={waveformRef}></div>
    </div>
  );
};

export default AudioAnswer;
