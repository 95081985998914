import React, { useState, useEffect } from 'react';
import { Button } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';

import rocketMan from 'assets/icons/rocketMan.png';
import useJournalStore from 'store/journalStore';
import Alert from 'components/Alert';

import useStyles from './styles';

const SurveyCompleted = () => {
  const classes = useStyles();
  const history = useHistory();
  const { surveys, updateSurveyStatus, error, doUpdateSurveys, doGetFilteredSurvey } = useJournalStore();
  const { journalId } = useParams();
  const [currentSurvey, setCurrentSurvey] = useState(null);

  const goBack = () => {
    history.goBack();
  };

  const goHome = async () => {
    history.push('/');
  };

  const updateStatus = async () => {
    if (currentSurvey && currentSurvey.statusId !== 3) {
      const { surveyResponseId } = currentSurvey;
      const statusUpdate = {
        surveyResponseId,
        statusId: 3,
      };
      const updatedSurvey = {
        ...currentSurvey,
        statusId: 3,
      };
      const resp = await updateSurveyStatus(statusUpdate);
      if (resp) {
        doUpdateSurveys(updatedSurvey);
        setCurrentSurvey(updatedSurvey);
      }
    }
  };

  useEffect(() => {
    updateStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSurvey]);

  useEffect(() => {
    if (surveys.length) {
      const updatedSurvey = surveys.find((survey) => survey.surveyResponseId === +journalId);
      setCurrentSurvey(updatedSurvey);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [journalId, surveys]);

  useEffect(() => {
    if (!surveys.length) {
      doGetFilteredSurvey(2);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [journalId]);

  return (
    <div className={classes.wrapper}>
      <div className={classes.surveyCompleted}>Survey complete!</div>
      <img src={rocketMan} className={classes.rocketManImage} alt="Rocket Man" />
      <div className={classes.quote}>
        {`The way we experience the world around us is a direct reflection of the world within us.
        
        - Gabrielle Bernstein
        `}
      </div>
      {error && <Alert severity="error" message={error} />}
      <div className={classes.buttonWrapper}>
        <Button color="primary" variant="outlined" className={classes.backButton} onClick={goBack}>
          Go Back
        </Button>
        <Button color="primary" variant="contained" className={classes.homeButton} onClick={goHome}>
          Return To Home
        </Button>
      </div>
    </div>
  );
};

export default SurveyCompleted;
