import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    fontFamily: 'Montserrat',
    fontSize: 24,
    color: theme.palette.text.primary,
  },
  startButton: {
    background: theme.palette.primary.dark,
    borderRadius: 10,
    color: 'white',
    width: 260,
    marginTop: 150,
  },
  confetti: {
    maxWidth: 250,
  },
}));

export default useStyles;
