import React, { useState, useEffect } from 'react';
import { Button } from '@material-ui/core';
import { Browser } from '@capacitor/browser';
import env from 'env';

import useStyles from './style';

const TextAnswer = ({ text, type = 'text' }) => {
  const classes = useStyles();
  const [value, setValue] = useState('');

  const downloadFile = async () => {
    try {
      if (env.isMobile) {
        Browser.open({ url: text }).then((val) => console.log(val.completed));
      } else {
        await fetch(text).then((response) => {
          response.blob().then((blob) => {
            let url = URL.createObjectURL(blob);
            let a = document.createElement('a');
            a.href = url;
            a.download = value;
            a.click();
          });
        });
      }
    } catch (error) {
      console.log(`error`, error.message);
    }
  };

  const getValue = () => {
    switch (type) {
      case 'checkboxList': {
        try {
          const checkBoxValue = JSON.parse(text);
          const ans = [];
          checkBoxValue.forEach((checkbox) => ans.push(checkbox.value));
          setValue(ans.join(','));
        } catch (error) {
          console.log(error);
        } finally {
          return;
        }
      }
      case 'checkboxLabel':
        {
          const isAnswerChecked = text === '1';
          setValue(isAnswerChecked.toString());
          return;
        }
        break;
      case 'rating': {
        try {
          const ratingValue = JSON.parse(text);
          setValue(ratingValue[0].key);
        } catch (error) {
          console.log(error);
        } finally {
          return;
        }
      }
      case 'radio':
      case 'dropdown': {
        try {
          const answer = JSON.parse(text);
          setValue(answer[0].value);
        } catch (error) {
          console.log(error);
        } finally {
          return;
        }
      }
      case 'fileUpload': {
        const name = text?.split('/');
        const decodedName = decodeURIComponent(name[name.length - 1]);
        setValue(decodedName);
        return;
      }
      default:
        return;
    }
  };

  useEffect(() => {
    getValue();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, text]);

  return (
    <div className={classes.textWrapper}>
      {value || text}
      {type === 'fileUpload' && (
        <Button
          onClick={downloadFile}
          className={classes.downloadButton}
          color="primary"
          variant="contained"
          size="small"
        >
          Download
        </Button>
      )}
    </div>
  );
};

export default TextAnswer;
