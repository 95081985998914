import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  loginForm: {
    width: 260,
    marginTop: 25,
    display: 'flex',
    flexDirection: 'column',
  },
  textFieldWrapper: {
    marginTop: 24,
  },
  label: {
    fontFamily: 'Montserrat',
    fontWeight: 400,
    fontSize: 12,
    color: theme.palette.text.primary,
    marginBottom: 5,
  },
  textField: {
    '& .MuiOutlinedInput-root': {
      border: `0.864078px solid #00AE85`,
      borderRadius: 10,
      height: 40,
      overflow: 'hidden',
    },
    '& .MuiOutlinedInput-input': {
      padding: '8px 14px',
      fontFamily: 'Montserrat',
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent',
    },

    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent',
    },
    '& .MuiInputBase-input': {
      height: 'inherit',
    },
  },
  nextButton: {
    background: theme.palette.primary.dark,
    borderRadius: 10,
    color: 'white',
    marginTop: 40,
    width: 260,
  },
  title: {
    fontFamily: 'Montserrat',
    fontSize: 20,
    color: theme.palette.text.primary,
    textAlign: 'center',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      marginTop: 10,
    },
  },
}));

export default useStyles;
