import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    padding: '14px 0',
    borderBottom: '1px solid #D8D8D8',
    color: theme.palette.text.primary,
    '&:last-child': {
      border: 0,
    },
  },
  description: {
    fontFamily: 'Montserrat',
    fontWeight: 300,
    fontSize: 14,
    marginLeft: 25,
  },
  rightArrow: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
  },
  textField: {
    '& .MuiInputBase-input': {
      padding: 0,
    },
    '& .MuiInputBase-root.Mui-disabled': {
      color: theme.palette.text.primary,
    },
    '& .MuiInput-underline.Mui-disabled:before': {
      borderBottomStyle: 'none',
    },
  },
  helperText: {
    fontFamily: 'Montserrat',
    fontSize: 12,
    color: theme.palette.error.dark,
  },
}));

export default useStyles;
