import React from 'react';
import { Typography, IconButton } from '@material-ui/core';
import dayjs from 'dayjs';
import { useLocation } from 'react-router-dom';

// import menuIcon from 'assets/icons/menu.svg';

import useStyles from './styles';

const EntryCard = ({ title, dueDate, numberOfQuestions = 0, date, surveyName, handleClick, description = '' }) => {
  const classes = useStyles();
  const { pathname } = useLocation();
  //TODO: Commented this to hide Share feature in the dropdown menu
  // const [menuEl, setMenuEl] = useState(null);

  // const handleMenuOpen = (e) => {
  //   e.stopPropagation();
  //   setMenuEl(e.currentTarget);
  // };

  // const handleMenuClose = (e) => {
  //   e.stopPropagation();
  //   setMenuEl(null);
  // };

  return (
    <div className={classes.wrapper} onClick={handleClick}>
      <div className={classes.titleWrapper}>
        <div className={classes.title}>
          <Typography variant="subtitle1">
            <b>{title}</b>
          </Typography>
          <div className={classes.surveyName}>
            <b>{surveyName}</b>
            <span className={classes.surveyDescription}>{description}</span>
          </div>
          <div className={classes.dateWrapper}>{`${numberOfQuestions} Questions`}</div>
        </div>
        <div className={classes.dueDate}>
          {/*<IconButton className={classes.menuIcon}>*/}
          {/*  <img src={menuIcon} alt="Menu Icon" />*/}
          {/*</IconButton>*/}
        </div>
      </div>
      {dueDate && pathname !== '/feed' && (
        <div className={classes.dueDateWrapper}>
          <div className={classes.dueDate}>Due {dayjs(dueDate).format('MM/DD/YYYY')}</div>
        </div>
      )}
      {/*//TODO: UnComment Menu Code to implement share feature.*/}
      {/*<Menu*/}
      {/*  id="share-menu"*/}
      {/*  anchorEl={menuEl}*/}
      {/*  keepMounted*/}
      {/*  open={Boolean(menuEl)}*/}
      {/*  onClose={handleMenuClose}*/}
      {/*  classes={{*/}
      {/*    paper: classes.menu,*/}
      {/*  }}*/}
      {/*>*/}
      {/*  <MenuItem onClick={handleMenuClose} classes={{ root: classes.menuItems }}>*/}
      {/*    Share*/}
      {/*  </MenuItem>*/}
      {/*</Menu>*/}
    </div>
  );
};

export default EntryCard;
