import React, { useRef, useState } from 'react';
import { IconButton } from '@material-ui/core';

import playButton from 'assets/icons/playButtonWhite.svg';
import pauseButton from 'assets/icons/pauseWhite.svg';

import useStyles from './styles';

const VideoAnswer = ({ video }) => {
  const classes = useStyles();
  const videoPlayer = useRef();
  const [isVideoPlaying, setVideoPlaying] = useState(false);

  const playVideo = () => {
    setVideoPlaying(true);
    videoPlayer.current.play();
  };

  const handleVideoPlay = () => {
    setVideoPlaying(false);
  };

  const handleVideoPause = () => {
    videoPlayer.current.pause();
    setVideoPlaying(false);
  };

  return (
    <div className={classes.videoWrapper}>
      <video
        src={video}
        ref={videoPlayer}
        className={classes.videoPlayer}
        onEnded={handleVideoPlay}
        onClick={handleVideoPause}
      />
      {!isVideoPlaying && (
        <div className={classes.playButton}>
          <IconButton onClick={playVideo}>
            <img src={playButton} alt="Play Button" />
          </IconButton>
        </div>
      )}
      {isVideoPlaying && (
        <div className={classes.iconWrapper}>
          <IconButton onClick={handleVideoPause} className={classes.pauseButton}>
            <img src={pauseButton} alt="Pause Button" />
          </IconButton>
        </div>
      )}
    </div>
  );
};

export default VideoAnswer;
