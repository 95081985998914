import React from 'react';
import dayjs from 'dayjs';

import TextAnswer from './TextAnswer';
import AudioAnswer from './AudioAnswer';
import VideoAnswer from './VideoAnswer';
import PhotoAnswer from './PhotoAnswer';
import MoodAnswer from './MoodAnswer';
import TextAreaAnswer from './TextAreaAnswer';
import TextBlock from './TextBlock';
import { questionType } from 'constants/questionType';

import useStyles from './styles';

const FeedViewer = ({ surveyAnswers, title, date }) => {
  const classes = useStyles();
  const getQuestionType = (ans) => {
    const { questionTypeId, answer, description } = ans;

    switch (questionTypeId) {
      case questionType.radioMatrix:
      case questionType.radioList:
        return <TextAnswer text={answer} type="radio" />;
      case questionType.checkBoxList:
        return <TextAnswer text={answer} type="checkboxList" />;
      case questionType.checkBox:
        return <TextAnswer text={answer} type="checkboxLabel" />;
      case questionType.dropdown:
        return <TextAnswer text={answer} type="dropdown" />;
      case questionType.textBlock:
        return <TextBlock description={description} />;
      case questionType.textArea:
        return <TextAreaAnswer text={answer} />;
      case questionType.audio:
        return <AudioAnswer audio={answer} />;
      case questionType.video:
        return <VideoAnswer video={answer} />;
      case questionType.fileUpload:
        return <TextAnswer text={answer} type="fileUpload" />;
      case questionType.photo:
        return <PhotoAnswer photo={answer} />;
      case questionType.mood:
        return <MoodAnswer currentMood={answer} />;
      case questionType.rating:
        return <TextAnswer text={answer} type="rating" />;
      case questionType.number:
      case questionType.text:
      case questionType.email:
      default:
        return <TextAnswer text={answer} />;
    }
  };

  return (
    <div className={classes.surveyWrapper}>
      <div className={classes.title}>
        {title} {date && `| ${dayjs(date).format('MMMM DD, YYYY')}`}
      </div>
      <div className={classes.contentWrapper}>
        {surveyAnswers &&
          surveyAnswers.map((ans) => (
            <div className={classes.surveyContent} key={ans.uid}>
              <div className={classes.questionText}>{ans.question}</div>
              <div className={classes.answerWrapper}>{getQuestionType(ans)}</div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default FeedViewer;
