import React from 'react';

import useStyles from './styles';

const TextBlock = ({ description }) => {
  const classes = useStyles();
  return <div className={classes.textBlockWrapper}>{`${description}`}</div>;
};

export default TextBlock;
