import React, { useEffect, useState } from 'react';
import { Typography, Button } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';

import journalIntro from 'assets/icons/journalIntro.png';
import NavBar from 'components/NavBar';
import CircularProgress from 'components/CircularProgress';
import useJournalStore from 'store/journalStore';
import useStyles from './styles';

const Intro = () => {
  const classes = useStyles();
  const {
    surveys,
    doGetFilteredSurvey,
    doGetSurveyQuestions,
    surveyQuestions,
    doGetSurveyAnswers,
    loadingQuestions,
    loadingAnswers,
    updateSurveyStatus,
  } = useJournalStore();
  const history = useHistory();
  const { surveyId } = useParams();
  const [currentSurvey, setCurrentSurvey] = useState(null);

  const startSurvey = async () => {
    const { statusId, surveyResponseId } = currentSurvey;
    if (statusId === 1 && statusId !== 2) {
      const updateStatus = {
        surveyResponseId,
        statusId: 2,
      };
      await updateSurveyStatus(updateStatus);
    }
    let uid = surveyQuestions[0].uid;
    history.push(`/survey/${currentSurvey.surveyResponseId}/${uid}`);
  };

  const getProgramName = () => {
    if (currentSurvey?.programName) {
      return (
        <span>
          {' '}
          with <b>{currentSurvey?.programName}</b>
        </span>
      );
    } else {
      return null;
    }
  };

  useEffect(() => {
    doGetFilteredSurvey('1,2');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [surveyId]);

  useEffect(() => {
    if (surveys.length) {
      const filterSurvey = surveys.find((survey) => survey.surveyResponseId === +surveyId);
      if (filterSurvey) {
        setCurrentSurvey(filterSurvey);
        doGetSurveyQuestions(filterSurvey.surveyResponseId);
        doGetSurveyAnswers(filterSurvey.surveyResponseId);
      } else {
        history.push('/');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [surveys, surveyId]);

  useEffect(() => {
    if (currentSurvey) {
      const { lastAttendedFormQuestionAnswerUid, surveyResponseId, statusId } = currentSurvey;
      if (statusId === 2) {
        if (lastAttendedFormQuestionAnswerUid) {
          history.push(`/survey/${surveyResponseId}/${lastAttendedFormQuestionAnswerUid}`);
        } else {
          let uid = surveyQuestions[0].uid;
          history.push(`/survey/${surveyResponseId}/${uid}`);
        }
      }
    }
  }, [surveyId, surveyQuestions]);

  const renderTitle = () => (
    <>
      You have <b>{currentSurvey?.totalQuestionsCount}</b> questions to answer today for{' '}
      <span className={classes.surveyName}>{currentSurvey?.activityName}</span>
      {getProgramName()}.
    </>
  );

  if (loadingAnswers || loadingQuestions) {
    return <CircularProgress />;
  }

  return (
    <div className={classes.wrapper}>
      {currentSurvey && (
        <>
          <div className={classes.backIconWrapper}>
            <NavBar title={renderTitle()} />
          </div>
          <div className={classes.contentWrapper}>
            <Typography variant="subtitle1" className={classes.desktopTitle}>
              {renderTitle()}
            </Typography>
            <img src={journalIntro} className={classes.introImg} alt="Intro" />
            <Button color="primary" variant="contained" className={classes.getStartedButton} onClick={startSurvey}>
              Get Started
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default Intro;
