import React from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { convertFromRaw, EditorState } from 'draft-js';

import useStyles from './style';

const TextAreaAnswer = ({ text }) => {
  const classes = useStyles();
  const getValue = () => {
    try {
      const content = convertFromRaw(JSON.parse(text));
      return EditorState.createWithContent(content);
    } catch (error) {}
  };
  return (
    <div className={classes.textWrapper}>
      <Editor
        editorState={getValue()}
        toolbarClassName={classes.toolBarClassName}
        wrapperClassName={classes.editorWrapperClassName}
        editorClassName={classes.editorClassName}
        readOnly={true}
      />
    </div>
  );
};

export default TextAreaAnswer;
