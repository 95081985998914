import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    width: 'calc(100% - 200px)',
    position: 'fixed',
    bottom: 0,
    padding: '20px 0 30px',
    right: 0,
    background: theme.palette.common.white,
    zIndex: 10,
    [theme.breakpoints.down('xs')]: {
      left: 0,
      right: 'auto',
      width: '100%',
    },
  },
  saveAndExitButton: {
    width: 175,
    height: 50,
    fontFamily: 'Montserrat',
    fontSize: 18,
    color: theme.palette.primary.dark,
    borderRadius: 10,
    marginRight: 10,
    [theme.breakpoints.down('xs')]: {
      width: 150,
      height: 40,
      fontSize: 14,
    },
  },
  nextButton: {
    width: 175,
    height: 50,
    fontFamily: 'Montserrat',
    fontSize: 18,
    color: '#FFFFFF',
    borderRadius: 10,
    [theme.breakpoints.down('xs')]: {
      width: 150,
      height: 40,
      fontSize: 14,
    },
  },
}));

export default useStyles;
