import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  textBlockWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
    paddingBottom: 90,
  },
  textBlock: {
    width: '100%',
    maxWidth: 924,
    padding: theme.spacing(2),
    flexWrap: 'wrap',
    maxHeight: 500,
    overflow: 'auto',
    whiteSpace: 'break-spaces',
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% - 48px)',
    },
  },
  textBlockContent: {
    display: 'flex',
    width: '100%',
    alignItems: 'flex-start',
  },
}));

export default useStyles;
