import React from 'react';
import { Typography } from '@material-ui/core';

import allCaughtUp from 'assets/icons/allCaughtUp.png';
import useStyles from './styles';

const AllCaughtUp = () => {
  const classes = useStyles();
  return (
    <div className={classes.allDone}>
      <div className={classes.caughtUp}>
        <img alt="all caught up" src={allCaughtUp} className={classes.caughtUpImg} />
        <i>
          <Typography variant="subtitle1">You are all caught up.</Typography>
        </i>
      </div>
    </div>
  );
};

export default AllCaughtUp;
