import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 76,
  },
  surveyCompleted: {
    fontFamily: 'Montserrat',
    fontWeight: 'bold',
    fontSize: 22,
    color: theme.palette.text.primary,
  },
  rocketManImage: {
    width: 480,
    height: 360,
    margin: '75px 0',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      height: 'auto',
    },
  },
  quote: {
    width: 460,
    fontFamily: 'Montserrat',
    fontWeight: 300,
    fontSize: 18,
    textAlign: 'center',
    color: '#000000',
    whiteSpace: 'break-spaces',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  buttonWrapper: {
    marginTop: 50,
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      flexDirection: 'column',
      marginTop: 20,
    },
  },
  backButton: {
    border: `2px solid ${theme.palette.success.main}`,
    borderRadius: 10,
    width: 236,
    marginRight: 20,
    fontSize: 18,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginRight: 0,
      marginBottom: 5,
    },
  },
  homeButton: {
    background: theme.palette.primary.dark,
    borderRadius: 10,
    fontFamily: 'Montserrat',
    fontSize: 18,
    color: '#FFFFFF',
    width: 236,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
}));

export default useStyles;
