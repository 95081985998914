import React, { useEffect, useState } from 'react';
import { Select, FormControl, MenuItem } from '@material-ui/core';

import NavBar from 'components/NavBar';
import ProgressButton from 'components/ProgressButton';
import Alert from 'components/Alert';
import useJournalStore from 'store/journalStore';
import { getQuestionRequired } from 'helper/helper';

import useStyles from './styles';

const DropdownQuestion = ({ title, uid, options, isRequired }) => {
  const classes = useStyles();
  const { updateAnswerByUID, error, surveyAnswers } = useJournalStore();
  const [dropdownAnswer, setDropdownAnswer] = useState('Select');
  const [loading, setLoading] = useState(false);

  const handleChange = ({ target: { value } }) => {
    const selectedOption = options.find((option) => option.value === value);
    if (selectedOption) {
      setDropdownAnswer(selectedOption);
    } else {
      setDropdownAnswer('Select');
    }
  };

  const handleAnswer = async () => {
    if (!isRequired && dropdownAnswer === 'Select') return true;
    setLoading(true);
    const answerFormData = new FormData();
    answerFormData.append('Uid', uid);
    answerFormData.append('AnswerText', JSON.stringify([dropdownAnswer]));
    const resp = await updateAnswerByUID(answerFormData);
    setDropdownAnswer('');
    setLoading(false);
    return resp;
  };

  useEffect(() => {
    const availableAnswer = surveyAnswers?.find((answer) => answer.uid === uid);
    if (!availableAnswer?.answer) return;
    const { answer } = availableAnswer;
    if (answer) {
      const ans = JSON.parse(answer);
      setDropdownAnswer(ans[0]);
    } else {
      setDropdownAnswer('Select');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uid]);

  return (
    <div className={classes.wrapper}>
      <NavBar title={title} />
      <div className={classes.dropdownWrapper}>
        <div className={classes.dropdown}>
          <FormControl
            variant="outlined"
            classes={{
              root: classes.formControlRoot,
            }}
          >
            <Select
              id="dropdown-question"
              value={dropdownAnswer?.value ? dropdownAnswer.value : dropdownAnswer}
              onChange={handleChange}
              classes={{
                select: classes.select,
              }}
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
                getContentAnchorEl: null,
              }}
            >
              <MenuItem value="Select">Select</MenuItem>
              {options.length &&
                options.map((option) => (
                  <MenuItem
                    key={option.key}
                    value={option.value}
                    classes={{
                      root: classes.list,
                    }}
                  >
                    {option.value}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </div>
      </div>
      {error && <Alert severity="error" message={error} />}
      <ProgressButton
        nextButtonDisabled={getQuestionRequired(isRequired, !dropdownAnswer)}
        nextButtonClicked={handleAnswer}
        loading={loading}
      />
    </div>
  );
};

export default DropdownQuestion;
