import { makeStyles } from '@material-ui/core';
import env from 'env';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    margin: '46px 0 0 95px',
    [theme.breakpoints.down('xs')]: {
      margin: '80px 0 0 0',
    },
  },
  userProfileWrapper: {
    display: 'flex',
    marginTop: 90,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      marginTop: 20,
      alignItems: 'center',
    },
  },
  imgWrapper: {
    position: 'relative',
    width: 220,
    marginTop: env.isMobile ? 30 : 0,
  },
  userImage: {
    width: 220,
    height: 220,
    borderRadius: 20,
  },
  cameraIconWrapper: {
    position: 'absolute',
    width: 70,
    height: 70,
    borderRadius: '50%',
    background: theme.palette.warning.dark,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    top: -40,
    right: -30,
    cursor: 'pointer',
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 82,
    width: '50%',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      marginLeft: 0,
      marginTop: 30,
      width: '80%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    background: '#FBFBFB',
    borderRadius: 15,
    padding: '0 25px',
    marginBottom: 30,
  },
  uploadPhotoDialog: {
    borderRadius: 15,
  },
  uploadPhotoModal: {
    padding: '28px 28px 50px 28px',
    display: 'flex',
    flexDirection: 'column',
    width: 315,
    alignItems: 'center',
    borderRadius: 15,
    [theme.breakpoints.down('xs')]: {
      width: 'auto',
    },
  },
  uploadButton: {
    background: theme.palette.success.main,
    color: '#FFFFFF',
    height: 50,
    borderRadius: 10,
    margin: '35px 0 20px 0',
    fontFamily: 'Montserrat',
  },
  takePhoto: {
    background: theme.palette.success.main,
    color: '#FFFFFF',
    height: 50,
    borderRadius: 10,
    fontFamily: 'Montserrat',
  },
  cropperWrapper: {
    position: 'relative',
  },
  minusButon: {
    position: 'absolute',
    bottom: '10%',
    left: '7%',
  },
  addButton: {
    position: 'absolute',
    bottom: '9%',
    right: '5%',
  },
  uploadFileInput: {
    display: 'none',
  },
  styledLink: {
    textDecoration: 'none',
  },
  settingNavbar: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
}));

export default useStyles;
