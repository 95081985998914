import React from 'react';
import { FormControlLabel, Checkbox } from '@material-ui/core';

import useStyles from './styles';

const CheckBox = ({ option, click, checked }) => {
  const classes = useStyles();
  const handleCheck = ({ target: { checked } }) => {
    click(checked, option);
  };

  return (
    <div className={classes.checkboxWrapper}>
      <FormControlLabel
        control={
          <Checkbox
            checked={checked}
            classes={{
              checked: classes.checked,
              root: classes.root,
            }}
          />
        }
        label={option.value}
        classes={{
          root: classes.formControlLabelRoot,
        }}
        onChange={handleCheck}
      />
    </div>
  );
};

export default CheckBox;
