import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  textFieldWrapper: {
    padding: '60px 0 0 90px',
    [theme.breakpoints.down('sm')]: {
      padding: '60px 0 0',
      width: '100%',
    },
  },
  textField: {
    maxWidth: 540,
    width: '100%',
    fontSize: 16,
    '& .MuiOutlinedInput-root': {
      border: `0.864078px solid #00AE85`,
      borderRadius: 10,
      height: 64,
      overflow: 'hidden',
    },
    '& .MuiOutlinedInput-input': {
      padding: '8px 32px',
      fontFamily: 'Montserrat',
      color: theme.palette.text.primary,
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent',
    },

    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent',
    },
    '& .MuiInputBase-input': {
      height: 'inherit',
    },
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
  helperText: {
    fontFamily: 'Montserrat',
    fontSize: 14,
    color: theme.palette.error.dark,
  },
}));

export default useStyles;
