import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    minHeight: 'calc(100vh - 40px)',
    padding: '20px 0',
    background: theme.palette.primary.main,
  },
  gradientWrapper: {
    background: 'linear-gradient(110.62deg, #00C5A3 -4.13%, #C3EAE0 51.61%)',
    boxShadow: '2px 9px 5px rgba(73, 73, 73, 0.15)',
    borderRadius: 10,
    padding: 10,
    width: '80%',
    minHeight: 650,
  },
  signUpFormWrapper: {
    background: 'white',
    borderRadius: 10,
    padding: 40,
    height: 'auto',
    minHeight: 650,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  navBar: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  arrowLeft: {
    boxShadow: '0px 1px 4px grey',
    borderRadius: 15,
  },
  title: {
    fontFamily: 'Montserrat',
    fontSize: 20,
    color: theme.palette.text.primary,
    textAlign: 'center',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      marginTop: 10,
    },
  },
}));

export default useStyles;
