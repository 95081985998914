import React, { useState, useEffect, useRef } from 'react';
import Croppie from 'croppie';
import { Dialog, Typography, Button } from '@material-ui/core';
import { Camera, CameraResultType } from '@capacitor/camera';
import dayjs from 'dayjs';
import env from 'env';
import NavBar from 'components/NavBar';
import Content from './Content';
import userProfileStore from 'store/userProfileStore';
import Alert from 'components/Alert';
import useSignUpStore from 'store/signupStore';
import userImage from 'assets/icons/userImage.svg';
import cameraIcon from 'assets/icons/camera.svg';
import useStyles from './styles';
import add from 'assets/icons/add.svg';
import minus from 'assets/icons/minus.svg';
import WebCamera from 'components/WebCamera';
import CircularProgress from 'components/CircularProgress';

import 'croppie/croppie.css';
import './override.css';

const UserProfile = () => {
  const classes = useStyles();
  const { doUploadImage, resetState, error } = useSignUpStore();
  const [isUploadModalOpen, setUploadModal] = useState(false);
  const [imageZoom, setImageZoom] = useState(0);
  const [isCrop, setCrop] = useState(false);
  const { navigation, userData, error: profileError, setUserProfilePhoto, loading } = userProfileStore();
  const [croppie, setCroppie] = useState(null);
  const [imageName, setImageName] = useState(null);
  const [uploadedImage, setUploadedImage] = useState(userImage);
  const [minZoom, setMinZoom] = useState(0);
  const [isCameraOpen, setCameraOpen] = useState(false);
  const [currentVideoStream, setCurrentStream] = useState([]);
  const uploadRef = useRef();

  const { firstName, lastName, profileUrl, email, phone, dateOfBirth, password } = userData;

  const crop = () => {
    const croppieElement = new Croppie(document.getElementById('crop'), {
      viewport: {
        width: 140,
        height: 140,
      },
      boundary: {
        width: 160,
        height: 160,
      },
      customClass: 'cropper',
    });
    setCroppie(croppieElement);
  };

  const handleUploadModal = () => {
    setCrop(false);
    setUploadModal(!isUploadModalOpen);
  };

  const handleCrop = (event) => {
    const file = event.target.files[0];
    setImageName(file.name);
    const reader = new FileReader();
    reader.onload = async (upload) => {
      const result = await upload.target.result;
      setUploadedImage(result);
      setCrop(true);
    };
    reader.readAsDataURL(file);
  };

  const handleZoomIncrease = () => {
    if (imageZoom < 1.5) {
      setImageZoom(imageZoom + 0.1);
    }
  };

  const handleZoomDecrease = () => {
    if (imageZoom >= minZoom) {
      setImageZoom(imageZoom - 0.1);
    }
  };

  const handleSliderChange = (event) => {
    const {
      target: { min },
    } = event;
    const currentZoom = croppie.get().zoom;
    setImageZoom(currentZoom);
    setMinZoom(min);
  };

  const handleFileUpload = () => {
    uploadRef.current.click();
  };

  const handleCropSave = async () => {
    try {
      const imageUrl = await croppie.result('base64');
      const fileName = imageName ? imageName : `${firstName} ${lastName}.jpg`;
      const resp = await doUploadImage(fileName, imageUrl);
      if (resp) {
        setUserProfilePhoto(imageUrl);
        resetState();
      } else {
        setUploadedImage(userImage);
      }
    } catch (err) {
      setUploadedImage(null);
    }
    setUploadModal(false);
  };

  const handleCameraOpen = () => setCameraOpen(!isCameraOpen);

  useEffect(() => {
    if (isCrop) {
      crop();
    }
  }, [isCrop]);

  useEffect(() => {
    if (croppie) {
      croppie.setZoom(imageZoom);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageZoom]);

  useEffect(() => {
    if (croppie) {
      croppie.bind({ url: uploadedImage }).then(() => {
        croppie.setZoom(imageZoom);
      });
      croppie.result('blob').then(() => {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [croppie]);

  useEffect(() => {
    localStorage.setItem('routeAt', 'profile');
    const crSlider = document?.getElementsByClassName('cr-slider')[0];
    crSlider?.addEventListener('change', handleSliderChange);

    return () => {
      localStorage.removeItem('routeAt');
      crSlider?.removeEventListener('change', handleSliderChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [croppie]);

  const takePhoto = async () => {
    if (env.isMobile) {
      const image = await Camera.getPhoto({
        quality: 90,
        resultType: CameraResultType.Uri,
      });
      setUploadedImage(image.webPath);
      setCrop(true);
    } else {
      navigator.mediaDevices
        .getUserMedia({ video: true })
        .then((stream) => {
          stream.getTracks().forEach((track) => track.stop());
          setCameraOpen(true);
        })
        .catch(() => {
          uploadRef.current.click();
        });
    }
  };

  useEffect(() => {
    if (!isCameraOpen && currentVideoStream) {
      currentVideoStream.forEach((current) => current.stop());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCameraOpen]);

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.settingNavbar}>
        <NavBar title="Settings" />
      </div>
      <div className={classes.userProfileWrapper}>
        <>
          <div className={classes.imgWrapper}>
            <img src={profileUrl || uploadedImage} className={classes.userImage} alt="User Profile" />
            <div className={classes.cameraIconWrapper} onClick={handleUploadModal}>
              <img src={cameraIcon} alt="Camera Icon" />
            </div>
            {error && <Alert severity="error" message={error} />}
            {profileError && <Alert severity="error" message={profileError} />}
          </div>
          <div className={classes.contentWrapper}>
            <div className={classes.content}>
              {firstName && <Content title="Name" value={`${firstName} ${lastName}`} name="firstName" />}
              {password && <Content title="Password" value="********" name="password" />}
              {email && <Content title="Email" value={email} name="email" />}
              {phone && <Content title="Cell Phone" value={phone} name="phone" />}
              {dateOfBirth && (
                <Content title="Birthdate" value={dayjs(dateOfBirth).format('YYYY-MM-DD')} name="dateOfBirth" />
              )}
            </div>
            <div className={classes.content}>
              <a
                href="https://afterschoolhq.zendesk.com/hc/en-us/sections/360001798931-FAQ?page=1#articles"
                target="_blank"
                className={classes.styledLink}
                rel="noreferrer"
              >
                <Content title="Help" />
              </a>
              <a
                href="https://afterschoolhq.com/privacy?utm_source=rise_app"
                target="_blank"
                className={classes.styledLink}
                rel="noreferrer"
              >
                <Content title="Privacy" />
              </a>
            </div>
          </div>
        </>
      </div>
      <Dialog
        open={isUploadModalOpen}
        onClose={handleUploadModal}
        classes={{
          paper: classes.uploadPhotoDialog,
        }}
      >
        <div className={classes.uploadPhotoModal}>
          <Typography variant="subtitle1">{isCrop ? 'Crop Photo' : 'Upload Profile Photo'}</Typography>
          {isCrop ? (
            <>
              <div id="crop" className={classes.cropperWrapper}>
                <div className={classes.minusButon} onClick={handleZoomDecrease}>
                  <img src={minus} alt="Minus" />
                </div>
                <div className={classes.addButton} onClick={handleZoomIncrease}>
                  <img src={add} alt="Add" />
                </div>
              </div>
              <Button
                color="primary"
                variant="contained"
                fullWidth
                className={classes.takePhoto}
                onClick={handleCropSave}
              >
                Crop & Save
              </Button>
            </>
          ) : (
            <>
              <Button
                color="primary"
                variant="contained"
                fullWidth
                className={classes.uploadButton}
                onClick={handleFileUpload}
              >
                Upload Photo
              </Button>
              <Button color="primary" onClick={takePhoto} variant="contained" fullWidth className={classes.takePhoto}>
                Take Photo
              </Button>
            </>
          )}
        </div>
        <input type="file" accept="image/*" className={classes.uploadFileInput} onChange={handleCrop} ref={uploadRef} />
      </Dialog>
      <Dialog
        open={isCameraOpen}
        onClose={handleCameraOpen}
        maxWidth="lg"
        classes={{
          paper: classes.uploadPhotoDialog,
        }}
      >
        {isCameraOpen && (
          <WebCamera
            setCapturedData={setUploadedImage}
            handleCameraOpen={handleCameraOpen}
            setCrop={setCrop}
            setVideoStream={setCurrentStream}
          />
        )}
      </Dialog>
    </div>
  );
};

export default UserProfile;
