import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 95px',
    marginTop: 59,
    maxWidth: 450,
    [theme.breakpoints.down('sm')]: {
      padding: 0,
      margin: '59px auto 0',
    },
  },
  loading: {
    maxWidth: 'none',
  },
  title: {
    marginBottom: 20,
  },
  previous: {
    margin: '35px 0 20px 0',
  },
}));

export default useStyles;
