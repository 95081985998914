import React from 'react';
import { Dialog, Button } from '@material-ui/core';
import cs from 'classnames';
import { useHistory, useParams } from 'react-router-dom';

import useJournalStore from '../../store/journalStore';
import Alert from 'components/Alert';
import useStyles from './styles';
import { logoutUser } from '../../helper/helper';

const ExitSurveyModal = ({ isOpen, onClose, currentPath }) => {
  const classes = useStyles();
  const history = useHistory();
  const { surveyId } = useParams();
  const { doExitSurvey, error, doResetError } = useJournalStore();

  const redirectToPath = async () => {
    const resp = await doExitSurvey(surveyId);
    if (resp) {
      if (currentPath === 'logout') {
        logoutUser();
        return;
      }
      history.push(currentPath);
      onClose();
    }
  };

  const handleReturnToSurvey = () => {
    doResetError();
    onClose();
  };

  return (
    <Dialog open={isOpen} classes={{ paper: classes.dialogWrapper }} onClose={onClose}>
      <div className={classes.dialogTitle}>Exiting this survey will delete your progress. Are you sure?</div>
      <div className={classes.dialogDescription}>
        To save your progress, please return to the survey and click "Save & Exit”
      </div>
      {error && <Alert severity="error" message={error} />}
      <div className={classes.buttonWrapper}>
        <Button className={cs(classes.buttonStyles, classes.linkButton)} onClick={redirectToPath}>
          Exit Survey
        </Button>
        <Button
          variant="contained"
          color="primary"
          className={cs(classes.buttonStyles, classes.primaryButton)}
          onClick={handleReturnToSurvey}
        >
          Return to Survey
        </Button>
      </div>
    </Dialog>
  );
};

export default ExitSurveyModal;
