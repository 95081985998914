import React from 'react';

import ProgressButton from 'components/ProgressButton';

import useStyles from './styles';
import NavBar from 'components/NavBar';

const TextBlockQuestion = ({ description }) => {
  const classes = useStyles();
  const handleAnswer = () => true;

  return (
    <div className={classes.textBlockWrapper}>
      <div className={classes.textBlockContent}>
        <NavBar isTextBlock={true} />
        <div className={classes.textBlock}>{`${description}`}</div>
      </div>
      <ProgressButton nextButtonDisabled={false} nextButtonClicked={handleAnswer} />
    </div>
  );
};

export default TextBlockQuestion;
